import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const PropertiesTable = ({ tableProps, keyWidth, items }) => 
  <Table {...tableProps} >
    <Table.Body>
      {items.filter(item => item != null && typeof item == 'object').map(({ key, value }) => 
        <Table.Row key={key}>
          <Table.Cell width={keyWidth}><b>{key}</b></Table.Cell>
          <Table.Cell>{
            value == null 
            ? null
            : typeof value === 'string' 
              ? value.split('\n').map((line, i) => <Fragment key={i}>{line}<br /></Fragment>)
              : value
          }</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>

PropertiesTable.propTypes = {
  tableProps: PropTypes.object,
  items: PropTypes.array.isRequired,
  keyWidth: PropTypes.number,
}

PropertiesTable.defaultProps = {
  keyWidth: 4,
}

export default PropertiesTable;