import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { FONT_OPTIONS, TEXT_ALIGNMENT_OPTIONS } from 'src/misc/dropdownOptions.js';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  cx: yup.number().required(),
  cy: yup.number().required(),
  r: yup.number().required(),
  angle: yup.number().required(),
  fontsize: yup.number().required(),
  fontname: yup.string().required(),
  fillcolor: yup.string().required().default('#000000'),
  text: yup.string().required(),
  is_flipped: yup.bool().default(true),
  match_condition: yup.string().nullable(),
  alignment: yup.string().required(),
});

const DialTemplateCurvedTextForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.match_condition = fields.match_condition ?? null;
    fields.fillcolor = fields.fillcolor?.trim();
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [{ ...fields, id_dial_template: formOpen?.id_dial_template }] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template_curved_text_by_pk ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Curved Text`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="text" component={Form.Input} label="Text" type="text" />
              <SemanticField name="cx" component={Form.Input} label="Arc center X (mm)" type="number" />
              <SemanticField name="cy" component={Form.Input} label="Arc center Y (mm)" type="number" />
              <SemanticField name="r" component={Form.Input} label="Arc radius (mm)" type="number" />
              <SemanticField name="angle" component={Form.Input} label="Arc center (deg)" type="number" />
              <SemanticField name="fontsize" component={Form.Input} label="Font size (mm)" type="number" />
              <SemanticField name="fontname" component={Form.Dropdown} label="Font name" selection options={FONT_OPTIONS} />
              <SemanticField name="alignment" component={Form.Dropdown} label="Text alignment" selection options={TEXT_ALIGNMENT_OPTIONS} />
              <SemanticField name="fillcolor" component={Form.Input} label="Color" />
              <SemanticField name="is_flipped" component={Form.Checkbox} label="Draw on top side of arc" />
              <SemanticField name="match_condition" component={Form.Input} label="Draw on condition" type="text" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default DialTemplateCurvedTextForm;