import gql from 'graphql-tag.macro';

export const m_scan_assembled = gql`
mutation gauge_scan_assembled($id: [String!]!, $id_employee: Int!) {
  update_gauge (where: { id: { _in: $id }}, _set: { id_assembled_by: $id_employee, assembled_at: "now()" }) {
    affected_rows
  }
}
`;

export const m_scan_angle_checked = gql`
mutation gauge_scan_angle_checked($id: [String!]!, $id_employee: Int!) {
  update_gauge (where: { id: { _in: $id }}, _set: { id_angle_checked_by: $id_employee, angle_checked_at: "now()" }) {
    affected_rows
  }
}
`;

export const m_scan_finished = gql`
mutation gauge_scan_finished($id: [String!]!, $id_employee: Int!) {
  update_gauge (where: { id: { _in: $id }}, _set: { id_finished_by: $id_employee, finished_at: "now()" }) {
    affected_rows
  }
}
`;

export const m_scan_checked = gql`
mutation gauge_scan_checked($id: [String!]!, $id_employee: Int!) {
  update_gauge (where: { id: { _in: $id }}, _set: { id_checked_by: $id_employee, checked_at: "now()" }) {
    affected_rows
  }
}
`;