import gql from 'graphql-tag.macro';

const f_unit_scalar_details = gql`
  fragment unit_scalar_details_fragment on unit {
    id
    name
    description
    mapping
    dimension
    id_unit_certificate
    notes
  }
`;

export const m_insert = gql`
mutation insert_unit($objects: [unit_insert_input!]!){
  insert_unit(objects: $objects) {
    affected_rows
  },
  ${f_unit_scalar_details},
}`;

export const m_delete = gql`
mutation delete_unit($id: Int!){
  delete_unit(where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_update = gql`
mutation update_unit($id: Int!, $_set: unit_set_input){
  update_unit(where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
  ${f_unit_scalar_details},
}`;

// Query used for prefilling unit update form with current values
export const q_update = gql`
query update_unit($id: Int!) {
  unit_by_pk(id: $id) {
    ...unit_scalar_details_fragment
  },
  ${f_unit_scalar_details},
}`;

// Query used for loading data required by unit form (e.g. dropdown fields)
export const q_form = gql`
query unit_form {
  unit {
    id
    name
    notes
  }
}`;

// Query used for loading unit list
export const q_list = gql`
query units($limit: Int, $offset: Int, $where: unit_bool_exp, $order_by: [unit_order_by!]) {
  list: unit(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    id
    name
    description
    mapping
    notes
    dimension
    unit_certificate {
      name
      notes
    }
  }
}`;