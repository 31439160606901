import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers.jsx';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  model_code: yup.string().required(),
  description: yup.string().required(),
  weight: yup.number().required(),
});

const AddressForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [fields] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.inventory_by_pk ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Inventory`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="model_code" component={Form.Input} label="Model code" />
              <SemanticField name="description" component={Form.TextArea} label="Description" autoHeight rows={1} />
              <SemanticField name="hsn_code" component={Form.Input} type="text" label="HSN code" />
              <SemanticField name="weight" component={Form.Input} type="number" min="0" step="0.01" label="Weight" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default AddressForm;