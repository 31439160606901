import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  x1: yup.number().required(),
  y1: yup.number().required(),
  x2: yup.number().required(),
  y2: yup.number().required(),
  strokewidth: yup.number().required(),
  strokecolor: yup.string().required().default('#000000'),
});

const DialTemplateLineForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.strokecolor = fields.strokecolor?.trim();
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [{ ...fields, id_dial_template: formOpen?.id_dial_template }] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template_line_by_pk ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Line`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="x1" component={Form.Input} label="Line start X (mm)" type="number" />
              <SemanticField name="y1" component={Form.Input} label="Line start Y (mm)" type="number" />
              <SemanticField name="x2" component={Form.Input} label="Line end X (mm)" type="number" />
              <SemanticField name="y2" component={Form.Input} label="Line end Y (mm)" type="number" />
              <SemanticField name="strokewidth" component={Form.Input} label="Stroke width (mm)" type="number" />
              <SemanticField name="strokecolor" component={Form.Input} label="Stroke color" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default DialTemplateLineForm;