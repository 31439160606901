import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';

const DelayedLoader = ({ delay=200 }) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => setShowLoader(true), delay);
    return () => clearTimeout(timerId);
  }, [delay]);

  return <Loader active={showLoader} />;
}

export default DelayedLoader;