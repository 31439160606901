import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const GAUGE_TYPE_OPTIONS = [
  { key: 'Piston', value: 'Piston', text: 'Piston' },
  { key: 'Diaphragm', value: 'Diaphragm', text: 'Diaphragm' },
];

const GAUGE_IP_CODE_OPTIONS = [
  { key: 'IP 65', value: 'IP 65', text: 'IP 65' },
  { key: 'IP 66', value: 'IP 66', text: 'IP 66' },
];

const validationSchema = yup.object().shape({
  max_accuracy: yup.number().required(),
  max_temperature: yup.number().required(),
  max_pressure: yup.number().required(),
  ip_code: yup.string().required(),
  type: yup.string().required(),
  name: yup.string().required(),
  has_dual_dials: yup.bool().default(false),
});

const SeriesForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [fields] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.series_by_pk ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Series`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="name" component={Form.Input} label="Name" />
              <SemanticField name="type" component={Form.Dropdown} label="type" selection options={GAUGE_TYPE_OPTIONS} />
              <SemanticField name="ip_code" component={Form.Dropdown} label="IP code" selection options={GAUGE_IP_CODE_OPTIONS} />
              <SemanticField name="max_accuracy" component={Form.Input} label="Max accuracy (%)" />
              <SemanticField name="max_pressure" component={Form.Input} label="Max working pressure (bar)" />
              <SemanticField name="max_temperature" component={Form.Input} label="Max temperature (°C)" />
              <SemanticField name="has_dual_dials" component={Form.Checkbox} label="Dual dials?" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default SeriesForm;