import numeral from 'numeral';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from 'src/routes/index.jsx';
import DataTable from 'src/misc/DataTable';

const rotatedTextStyle = {
  writingMode: 'tb-rl',
  textAlign: 'bottom',
  transform: 'rotate(-180deg)',
}

const GaugeLinesList = ({ lines, currency, style, line_actions, handleContextMenu }) => {
  const history = useHistory();
  const me = useContext(UserContext);
  if (lines.length === 0) return null;
  const footerTotals = lines
    .map(line => [
      line.qty_assembled,
      line.qty_angle_checked,
      line.qty_calibrated,
      line.qty_dial_crosschecked,
      line.qty_switch_crosschecked,
      line.qty_finished,
      line.qty_checked,
      line.qty_packed,
      line.quantity,
      line.amount,
    ])
    .reduce((prevArr, curArr) => prevArr.map((val, i) => val + curArr[i]));

  return (
    <div style={style}>
      <DataTable
        tableProps={{ fixed: true, celled: true, selectable: true, basic: 'very' }}
        rowProps={{ style: { cursor: 'pointer' }}}
        endReached={true}
        onClick={(id) => history.push(`/gauge_lines/${id}`)}
        handleContextMenu={handleContextMenu}
        columns={[
          { key: 'id_sales_order', value: 'OA #', width: 1 },
          { key: 'line', value: 'Line', width: 1 },
          { key: 'model_code', value: 'Model code', width: 4 },
          { key: 'id_invoice', value: 'Invoice', width: 2 },
          { key: 'assembled', value: <span style={rotatedTextStyle}>Assembly</span>, type: 'percent' },
          { key: 'angle_checked', value: <span style={rotatedTextStyle}>Angle Check</span>, type: 'percent' },
          { key: 'calibrated', value: <span style={rotatedTextStyle}>Calibration</span>, type: 'percent' },
          { key: 'dial_crosschecked', value: <span style={rotatedTextStyle}>Dial Cross</span>, type: 'percent' },
          { key: 'switch_crosschecked', value: <span style={rotatedTextStyle}>Switch Cross</span>, type: 'percent' },
          { key: 'finished', value: <span style={rotatedTextStyle}>Finishing</span>, type: 'percent' },
          { key: 'checked', value: <span style={rotatedTextStyle}>QC</span>, type: 'percent' },
          { key: 'packed', value: <span style={rotatedTextStyle}>Packing</span>, type: 'percent' },
          { key: 'price', value: `Price (${currency})`, width: 1, textAlign: 'right', hidden: !me.isSales && !me.isAccounts && !me.isOwner  },
          { key: 'quantity', value: 'Quantity', textAlign: 'right', width: 1 },
          { key: 'amount', value: `Amount (${currency})`, width: 1, textAlign: 'right', hidden: !me.isSales && !me.isAccounts && !me.isOwner  },
          { key: 'line_actions', value: '', width: 1, ignoreClick: true, hidden: line_actions == null },
        ]}
        rows={lines.map(({ price, amount, quantity, ...line }) => ({
          key: line.id,
          id_invoice: line.invoice ? line.invoice.id : null,
          assembled: { value: line.qty_assembled, maxValue: quantity },
          angle_checked: { value: line.qty_angle_checked, maxValue: quantity },
          calibrated: { value: line.qty_calibrated, maxValue: quantity },
          dial_crosschecked: { value: line.qty_dial_crosschecked, maxValue: quantity },
          switch_crosschecked: { value: line.qty_switch_crosschecked, maxValue: quantity },
          finished: { value: line.qty_finished, maxValue: quantity },
          checked: { value: line.qty_checked, maxValue: quantity },
          packed: { value: line.qty_packed, maxValue: quantity },
          quantity,
          price: numeral(price).format('0,0.00'),
          amount: numeral(amount).format('0,0.00'),
          line_actions: line_actions != null && line_actions(line),
          ...line,
        }))}
        footer={[
          '',
          '',
          '', 
          '', 
          { value: footerTotals[0], maxValue: footerTotals[8] },
          { value: footerTotals[1], maxValue: footerTotals[8] },
          { value: footerTotals[2], maxValue: footerTotals[8] },
          { value: footerTotals[3], maxValue: footerTotals[8] },
          { value: footerTotals[4], maxValue: footerTotals[8] },
          { value: footerTotals[5], maxValue: footerTotals[8] },
          { value: footerTotals[6], maxValue: footerTotals[8] },
          { value: footerTotals[7], maxValue: footerTotals[8] },
          '',
          footerTotals[8],
          numeral(footerTotals[9]).format('0,0.00'),
          '',
        ]}
      />
    </div>
  );
}

export default GaugeLinesList;
