import { useMutation, useSubscription } from '@apollo/client';
import dayjs from 'dayjs';
import numeral from 'numeral';
import React, { useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, Menu, Modal, Portal, Table } from 'semantic-ui-react';
import DelayedLoader from 'src/misc/DelayedLoader';
import { fragmentNewlines } from "src/misc/helpers";
import { useForm } from 'src/misc/hooks';
import PropertiesTable from "src/misc/PropertiesTable";
import TitledSegment from 'src/misc/TitledSegment';
import { isEmptyString, isNumber } from 'src/misc/validators';
import { UserContext } from 'src/routes/index.jsx';
import GaugeLinesTable from '../gauge_line/Table';
import InventorySaleLinesTable from '../inventory_sale_line/Table';
import InvoiceForm from './Form';
import * as QUERIES from './queries.js';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  idHeader: {
    fontSize: '1.8rem',
    fontWeight: 700,
    marginBottom: 8,
  },
  statusSubheader: {
    fontSize: '1.2rem',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  mainContainer: {
    '&>*': {
      padding: '3em 3em',
      '&>h2': {
        color: 'hsl(0, 0%, 40%)',
        fontWeight: 'bold',
        marginBottom: '1em',
      },
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});

const ChargeRow = ({ description, amount, is_taxable = true, style }) => 
  <Table.Row style={style}>
    <Table.Cell>{`${description}${is_taxable ? '' : ' (Not Taxable)'}`}</Table.Cell>
    <Table.Cell textAlign='right'>{numeral(amount).format('0,0.00')}</Table.Cell>
  </Table.Row>

const InvoiceCharges = ({ invoice }) => 
  <div>
    <h2>Charges</h2>
    <Table collapsing celled basic='very' textAlign="right" >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={12}>Description</Table.HeaderCell>
          <Table.HeaderCell width={4} textAlign="right">{`Amount (${invoice.currency.symbol})`}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <ChargeRow description="Basic amount" amount={invoice.basic_amount} style={{ fontWeight: 'bold' }} />
        { invoice.all_charges.map((charge, i) => <ChargeRow key={i} {...charge} />) }
        <ChargeRow description={`Taxable Amount`} amount={invoice.taxable_amount} style={{ fontWeight: 'bold' }} />
        { invoice.cgst_amount > 0 && <ChargeRow description={`CGST @ ${invoice.cgst}%`} amount={invoice.cgst_amount} /> }
        { invoice.sgst_amount > 0 && <ChargeRow description={`SGST @ ${invoice.sgst}%`} amount={invoice.sgst_amount} /> }
        { invoice.igst_amount > 0 && <ChargeRow description={`IGST @ ${invoice.igst}%`} amount={invoice.igst_amount} /> }
        { invoice.tcs_amount > 0 && <ChargeRow description={`TCS @ ${invoice.tcs}%`} amount={invoice.tcs_amount} /> }
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell><b>Total</b></Table.HeaderCell>
          <Table.HeaderCell textAlign='right'><b>{numeral(invoice.total_amount).format('0,0.00')}</b></Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  </div>

const AddLinesForm = ({ idInvoice, setIdInvoice }) => {
  const initialValues = { id_sales_order: '', line: '' };
  const [m_add_sales_order] = useMutation(QUERIES.m_add_sales_order);
  const [m_add_sales_order_line] = useMutation(QUERIES.m_add_sales_order_line);
  const [formValues, setFormValues, , , formProps] = useForm(initialValues);
  const handleReset = () => { 
    setFormValues(initialValues); 
    setIdInvoice(null); 
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEmptyString(formValues.id_sales_order)) return;
    if (isEmptyString(formValues.line)) m_add_sales_order({ variables: { id_invoice: idInvoice, id_sales_order: formValues.id_sales_order } });
    else m_add_sales_order_line({ variables: { id_invoice: idInvoice, id_sales_order: formValues.id_sales_order, line: formValues.line }});
    setIdInvoice(null);
  }
  return (
    <Modal dimmer="blurring" open={idInvoice!=null} onClose={handleReset} size="mini">
      <Modal.Header>Add sales order lines</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input label="Sales Order #" { ...formProps.id_sales_order } />
          <Form.Input label="Line #" type="number" { ...formProps.line } />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' onClick={handleReset} ><Icon name='remove' />Discard</Button>
        <Button color='green' onClick={handleSubmit} ><Icon name='checkmark' />Add</Button>
      </Modal.Actions>
    </Modal>
  );
}

const InvoiceDetails = ({ match }) => {
  const me = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [invoiceFormOpen, setInvoiceFormOpen] = useState(null);
  const [contextMenuState, setContextMenuState] = useState(null);
  const { data, error } = useSubscription(QUERIES.s_detail, { variables: { id: match.params.id }});
  const [m_update_invoice] = useMutation(QUERIES.m_update, { variables: { id: match.params.id }});
  const [m_delete_invoice] = useMutation(QUERIES.m_delete, { variables: { id: match.params.id }});
  const [m_remove_sales_order_line] = useMutation(QUERIES.m_remove_sales_order_line);
  const [addLinesFormState, setAddLinesFormState] = useState(null);
  if (data == null) return <DelayedLoader />
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  const invoice = data.invoice_view[0];
  const isArchived = !['Sales', 'Production'].includes(invoice.status);

  const handleOpenContexMenu = id_line => event => {
    event.preventDefault();
    setContextMenuState({
      id_line,
      mouseX: event.pageX - 2,
      mouseY: event.pageY - 4,
    });
  };

  const LineContextMenu = () => {
    if (contextMenuState != null) {
      return (
        <Portal open={true} onClose={() => setContextMenuState(null)} >
          <Menu vertical
            style={{
              position: 'absolute',
              top: contextMenuState.mouseY,
              left: contextMenuState.mouseX,
              zIndex: 1000,
            }}
          >
            { me.isSales && !isArchived && 
              <Menu.Item onClick={() => { 
                setContextMenuState(null); 
                if (window.confirm('Confirm removal from invoice?'))
                  m_remove_sales_order_line({ variables: { id: contextMenuState.id_line }})
                }}
              >Delete<Icon name="warning sign" /></Menu.Item> 
            }
          </Menu>
        </Portal>
      );
    } else {
      return null;
    }
  }
  
  return (
    <div className={classes.mainContainer}>
      <LineContextMenu />
      <InvoiceForm formOpen={invoiceFormOpen} setFormOpen={setInvoiceFormOpen} />
      <AddLinesForm idInvoice={addLinesFormState} setIdInvoice={setAddLinesFormState} />
      <div className={classes.flexContainer}>
        <div className={classes.details}>
          <Grid celled='internally'>
            <Grid.Row>
              <Grid.Column width={8}>
                <div className={classes.idHeader}>{invoice.id}</div>
                <div className={classes.statusSubheader}>{invoice.status}</div>
              </Grid.Column>
              <Grid.Column width={8}>
                <b>Date :</b> {dayjs(invoice.invoice_date).format('DD MMM YY')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as='h3'>Billing</Header>
                <Header as={Link} to={`/companies/${invoice.customer_billing.id}`}>{invoice.customer_billing.name}</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Street', value: invoice.address_billing.street },
                    { key: 'City', value: invoice.address_billing.city },
                    { key: 'Zipcode', value: invoice.address_billing.zipcode },
                    { key: 'District', value: invoice.address_billing.district },
                    { key: 'State', value: invoice.address_billing.state },
                    { key: 'Country', value: invoice.address_billing.country },
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as='h3'>Shipping</Header>
                <Header as={Link} to={`/companies/${invoice.customer_shipping.id}`}>{invoice.customer_shipping.name}</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Street', value: invoice.address_shipping.street },
                    { key: 'City', value: invoice.address_shipping.city },
                    { key: 'Zipcode', value: invoice.address_shipping.zipcode },
                    { key: 'District', value: invoice.address_shipping.district },
                    { key: 'State', value: invoice.address_shipping.state },
                    { key: 'Country', value: invoice.address_shipping.country },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as='h3'>Freight</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Freight carrier', value: invoice.freight_carrier },
                    { key: 'Freight mode', value: invoice.freight_mode },
                    { key: 'Freight terms', value: invoice.freight_terms },
                    { key: 'Airway Bill #', value: invoice.airwaybill_number },
                  ]}
                />
              </Grid.Column>
              {(me.isSales || me.isAccounts || me.isOwner) 
                ? <Grid.Column width={8}>
                    <Header as='h3'>Business Terms</Header>
                    <PropertiesTable
                      tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                      items={[
                        { key: 'Payment due', value: dayjs(invoice.invoice_date).add(invoice.credit_days, 'days').format('DD MMM YY') },
                        { key: 'Payment terms', value: `${invoice.payment_terms}\n\n${invoice.bank_terms}` },
                        { key: 'Trade terms', value: invoice.trade_terms },
                        { key: 'Insurance terms', value: invoice.insurance_terms },
                      ]}
                    />
                  </Grid.Column>
                : null
              }
            </Grid.Row>
            {(me.isSales || me.isAccounts || me.isOwner) 
              ? <Grid.Row>
                  <Grid.Column width={8}>
                    <Header as='h3'>E-invoice</Header>
                    <PropertiesTable
                      tableProps={{ basic: 'very', celled: true, compact: 'very', 'fixed': true }}
                      items={[
                        { key: 'Supply type', value: invoice.einv_suptyp },
                        { key: 'IRN', value: invoice.einv_irn },
                        { key: 'IRN date', value: invoice.einv_irn_date == null ? '' : dayjs(invoice.einv_irn_date).format('DD MMM YY') },
                        { key: 'Ewaybill #', value: invoice.einv_ewaybill_num },
                      ]}
                    />
                  </Grid.Column>
                </Grid.Row>
              : null
            }
            {invoice.notes && <Grid.Row>
              <Grid.Column>
                <Header as='h4'>Notes</Header>
                <p>{fragmentNewlines(invoice.notes)}</p>
              </Grid.Column>
            </Grid.Row>}
          </Grid>
        </div>
        <div className={classes.actions}>
          <TitledSegment title='Actions' >
            { me.isSales && !isArchived &&
              <button onClick={() => setAddLinesFormState(invoice.id)}>
                Add sales order lines
              </button>
            }
            { me.isSales && invoice.status === 'Sales' &&
              <button onClick={() => {
                if (window.confirm('Confirm release to production?'))
                  m_update_invoice({ variables: { _set: {
                    status: 'Production',
                    id_production_released_by: me.id,
                    production_released_at: 'now()',
                  }}});
                }}
              >
                Release to production
              </button>
            }
            { me.isSales && invoice.status === 'Production' && invoice.id_finalized_by == null &&
              <button onClick={() => {
                const final_id = window.prompt('Please enter final invoice id');
                if (final_id != null && !isEmptyString(final_id))
                  m_update_invoice({ variables: { _set: {
                    id: final_id,
                    id_finalized_by: me.id,
                    finalized_at: 'now()',
                  }}}).then(() => history.replace(`/invoices/${final_id}`));
                }}
              >
                Finalize
              </button>
            }
            { me.isSales && !isArchived && 
              <button onClick={() => setInvoiceFormOpen({ id: invoice.id })}>
                <Icon name='edit' />
                Edit
              </button> 
            }
            { me.isSales && !isArchived &&
              <button onClick={() => window.confirm('Confirm deletion?') && m_delete_invoice() && history.push('/invoices')}>
                <Icon color="red" name="trash" />
                Delete
              </button>
            }
          </TitledSegment>
          <TitledSegment title='Documents'>
            {me.isSales &&
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/invoice`)}>
                <Icon name="file pdf" />
                Invoice
              </button>
            }
            {me.isSales &&
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/converted-invoice`)}>
                <Icon name="file pdf" />
                Converted Invoice
              </button>
            }
            {me.isSales && 
              <button 
                onClick={() => window.open(`/pdf/einvoice?id=${invoice.id}`)}
                disabled={invoice.id.startsWith('T') || (invoice.currency.name !== 'INR' && invoice.exchange_rate === 0)}
              >
                <Icon name="file code" />
                Einvoice JSON
              </button>
            }
            <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/calibration-certificates`)}>
              <Icon name="file pdf" />
              Calibration Certificates
            </button>
            <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/test-certificates`)}>
              <Icon name="file pdf" />
              Test Certificates
            </button>
            <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/gauge-labels`)}>
              <Icon name="file pdf" />
              Gauge Labels
            </button>
            <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/inner-box-labels`)}>
              <Icon name="file pdf" />
              Inner Box Labels
            </button>
            <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/outer-box-labels`)}>
              <Icon name="file pdf" />
              Outer Box Labels
            </button>
            <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/working-labels`)}>
              <Icon name="file pdf" />
              Working Labels
            </button>
          </TitledSegment>
        </div>
      </div>
      {invoice.gauge_lines.length > 0 &&
        <div>
          <h2>Gauges</h2>
          <GaugeLinesTable lines={invoice.gauge_lines} currency={invoice.currency.symbol} handleContextMenu={handleOpenContexMenu} />
        </div>
      }
      {invoice.inventory_sale_lines.length > 0 &&
        <div>
          <h2>Inventories</h2>
          <InventorySaleLinesTable lines={invoice.inventory_sale_lines} currency={invoice.currency.symbol} handleContextMenu={handleOpenContexMenu} />
        </div>
      }
      {(me.isSales || me.isAccounts || me.isOwner) ? <InvoiceCharges invoice={invoice} /> : null}
    </div>
  );   
}

export default withRouter(InvoiceDetails);