import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Icon } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import Form from './Form';
import * as QUERIES from './queries.js';

const ScaleList = () => {
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(null);
  const [m_delete] = useMutation(QUERIES.m_delete);
  const me = React.useContext(UserContext);
  return (
    <>
      <Form formOpen={formOpen} setFormOpen={setFormOpen} onComplete={() => history.go(0)} />
      <CollectionList
        collectionName="Scales"
        query={QUERIES.q_list}
        handleOpenCreateForm={me.isProduction && (() => setFormOpen({ }))}
        tableColumns={[
          { key: 'range_from', value: 'FROM', width: '8%' },
          { key: 'range_to', value: 'TO', width: '8%' },
          { key: 'major_markings', value: 'MAJOR MARKINGS', width: '10%' },
          { key: 'text_markings', value: 'TEXT MARKINGS', width: '10%' },
          { key: 'minor_markings', value: 'MINOR MARKINGS', width: '20%' },
          { key: 'callout_markings', value: 'CALLOUT MARKINGS', width: '10%' },
          { key: 'notes', value: 'NOTES', width: 'auto' },
          { key: 'line_actions', sortable: false, value: '', width: '8%', hidden: !me.isProduction },
        ]}
        mapDataRowToTable={scale => ({
          ...scale,
          key: scale.id,
          major_markings: scale.major_markings.join(', '),
          minor_markings: scale.minor_markings.join(', '),
          text_markings: scale.text_markings.join(', '),
          callout_markings: scale.callout_markings.join(', '),
          line_actions: (
            <div>
              <Button onClick={() => setFormOpen({ id: scale.id })} style={{ background: 'none' }} icon >
                <Icon name='pencil' />
              </Button>
              <Button style={{ background: 'none' }} icon 
                onClick={async () => {
                  if (window.confirm('Confirm deletion? Cannot delete if used anywhere.')) {
                    await m_delete({ variables: { id: scale.id }});
                    history.go(0);
                  }
                }}
              >
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
        })}
        defaultSortOrder={[{ range_from: 'asc' }, { range_to: 'asc' }, { id: 'asc' }]}
        defaultFilters={{ notes: '' }}
        filters={[
          { key: 'notes', label: 'NOTES', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { };
          if (!isEmptyString(filterOptions.notes))   queryFilters.notes = { _ilike: `%${filterOptions.notes}%` };
          return queryFilters;
        }}
      />
    </>
  )
};
export default ScaleList;