import gql from 'graphql-tag.macro';

export const m_insert_gauge_line = gql`
  mutation insert_gauge_line($gauge: gauge_line_insert_input!){
    insert_gauge_line(objects: [$gauge]) {
      affected_rows
    }
  }
`;

export const m_insert_dial = gql`
  mutation insert_dial($dial: dial_insert_input!){
    insert_dial(objects: [$dial]) {
      affected_rows
    }
  }
`;

export const m_insert_gauge_switch = gql`
  mutation insert_gauge_switch($gauge_switch: [gauge_switch_insert_input!]!){
    insert_gauge_switch(objects: $gauge_switch) {
      affected_rows
    }
  }
`;

export const m_delete_gauge_line = gql`
  mutation delete_gauge_line($id: String!){
    delete_gauge_line(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const m_delete_dial = gql`
  mutation delete_dial($id: String!){
    delete_dial(where: { id_gauge_line: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const m_delete_gauge_switch = gql`
  mutation delete_gauge_switch($id: String!){
    delete_gauge_switch(where: { id_gauge_line: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const m_update_gauge_line = gql`
  mutation update_gauge_line($id: String!, $gauge: gauge_line_set_input){
    update_gauge_line(where: { id: { _eq: $id } }, _set: $gauge) {
      affected_rows
    }
  }
`;

// Query used for prefilling gauge_line update form with current values
export const q_update = gql`
  query update_gauge_line($id: String!) {
    gauge_line_by_pk(id: $id) {
      id
      id_sales_order
      line
      id_invoice
      end_customer_po
      article_number
      price
      model_code
      customer_code
      label_notes
      notes
      id_company_label
      id_company_documents
      connection_adapter
      process_connection
      max_pressure
      max_temperature
      max_accuracy
      is_upside_down
      require_calibration_certificate
      require_test_certificate
      mounting
      bracket
      dial {
        id
        id_dial_template
      }
      switches (order_by: { id: asc }) {
        id
        setpoint
        range_to
        is_ascending
        id_unit
      }
    } 
  }
`;

export const q_form = gql`
query gauge_line_form {
  series {
    id
    name
  }

  company {
    id
    name
  }

  dial_template {
    id
    notes
  }

  mounting_options: gauge_line (distinct_on: mounting) {
    mounting
  }
  bracket_options: gauge_line (distinct_on: bracket) {
    bracket
  }

  unit {
    id
    name
    notes
  }
}`;

// Fragment to load fields required in gauge line table for invoice / sales order
export const f_gauge_line_table_details = gql`
  fragment gauge_line_view_table_details_fragment on gauge_line_view {
    id
    id_sales_order
    line
    invoice {
      id
      status
    }
    model_code
    qty_assembled
    qty_angle_checked
    qty_calibrated
    qty_dial_crosschecked
    qty_switch_crosschecked
    qty_finished
    qty_checked
    qty_packed
    quantity
    price
    amount
  }
`;

export const s_detail = gql`
  subscription gauge_line ($id: String!) {
    gauge_line_view (where: { id: { _eq: $id }}) {
      id
      sales_order {
        id
        currency {
          id
          symbol
        }
      }
      line
      invoice {
        id
        status
      }
      model_code
      customer_code
      end_customer_po
      price
      quantity
      amount
      notes
      company_documents {
        name
      }
      company_label {
        name
      }
      connection_adapter
      process_connection
      max_pressure
      max_temperature
      max_accuracy
      mounting
      article_number
      bracket
      dial {
        customer_logo
        dial_template {
          notes
        }
      }
      switches (order_by: { id: asc }) {
        setpoint
        range_to
        unit {
          id
          name
        }
        is_ascending
      }
      scales (order_by: { scale_number: asc }) {
        id
        scale_number
        is_ascending
        multiplier
        unit {
          id
          name
        }
        colorbands
        deadband_from
        deadband_to
        label_text
        scale {
          range_from
          range_to
          major_markings
          minor_markings
          text_markings
          callout_markings
        }
      }
      gauges (order_by: { line_item: asc }) {
        id
        line_item
        tag_number
        assembled_at
        angle_checked_at
        dial_calibrations (order_by: { calibrated_at: desc }) {
          calibrated_at
        }
        dial_crosschecks (order_by: { checked_at: desc }) {
          checked_at
        }
        switch_crosschecks (order_by: { checked_at: desc }) {
          checked_at
        }
        finished_at
        checked_at
        packed_at
      }
      qty_assembled
      qty_angle_checked
      qty_calibrated
      qty_dial_crosschecked
      qty_switch_crosschecked
      qty_finished
      qty_checked
      qty_packed
    }
  }
`;