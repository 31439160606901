import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CollectionList from 'src/misc/CollectionList';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import Form from './Form';
import * as QUERIES from './queries.js';

const InventoryList = () => {
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(null);
  const me = React.useContext(UserContext);
  return (
    <>
      <Form formOpen={formOpen} setFormOpen={setFormOpen} 
        onComplete={data => history.push(`/inventories/${data.insert_inventory.returning[0].id}`)} 
      />
      <CollectionList
        collectionName="Inventories"
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`/inventories/${id}`)}
        handleOpenCreateForm={me.isSales && (() => setFormOpen({ }))}
        tableColumns={[
          { key: 'model_code', value: 'Model code', width: '25%' },
          { key: 'description', value: 'Description', width: '45%' },
          { key: 'category', value: 'Category', width: '20%' },
          { key: 'qty_stock', value: 'Stock', width: '10%' },
        ]}
        mapDataRowToTable={inventory => ({
          ...inventory,
          key: inventory.id,
        })}
        defaultSortOrder={[{ 'model_code': 'asc' }]}
        defaultFilters={{ description: '' }}
        filters={[
          { key: 'description', label: 'DESCRIPTION', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = {};
          if (!isEmptyString(filterOptions.description))   queryFilters._or = [
            { description: { _ilike: `%${filterOptions.description}%` }},
            { model_code: { _ilike: `%${filterOptions.description}%` }},
          ];
          return queryFilters;
        }}
      />
    </>
  )
};
export default InventoryList;