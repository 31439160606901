import gql from 'graphql-tag.macro';

export const f_scalar_details = gql`
  fragment dial_template_arc_scalar_details_fragment on dial_template_arc {
    id
    cx
    cy
    d
    start_angle
    arc_angle
    strokewidth
    strokecolor
    notes
  }
`;

export const m_delete = gql`
mutation delete_dial_template_arc($id: Int!){
  delete_dial_template_arc (where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_insert = gql`
mutation insert_dial_template_arc ($objects: [dial_template_arc_insert_input!]!){
  insert_dial_template_arc(objects: $objects) {
    affected_rows
  },
  ${f_scalar_details},
}`;

export const m_update = gql`
mutation update_dial_template_arc ($id: Int!, $_set: dial_template_arc_set_input){
  update_dial_template_arc (where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
  ${f_scalar_details},
}`;

export const q_update = gql`
query update_dial_template_arc ($id: Int!) {
  dial_template_arc_by_pk (id: $id) {
    ...dial_template_arc_scalar_details_fragment
  },
  ${f_scalar_details},
}`;