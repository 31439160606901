import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  segment: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      padding: '8px',
      border: '1px solid hsl(0,0%,80%)',
    },
    '&>:first-child': {
      textAlign: 'center',
      fontSize: '16px',
      margin: '0',
      border: '1px solid hsl(0,0%,15%)',
      borderRadius: '10px 10px 0 0',
      backgroundColor: 'hsla(0,0%,15%)',
      color: 'hsl(0,0%,100%)',
    },
    '&>:nth-child(n+2)': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'hsl(0,0%,80%)',
      },
      '&:nth-last-child(n+2)': {
        borderBottom: 'none',
      },
      '&:last-child': {
        borderRadius: '0 0 10px 10px',
      }
    },
  },
});

const TitledSegment = ({ title, children, ...props }) => {
  const classes = useStyles();
  if (Array.isArray(children) && children.filter(child => !!child).length === 0)  return null;
  if (!children)  return null;
  return (
    <div {...props} className={classes.segment}>
      <h3>{title}</h3>
      { children }
    </div>
  );
}

export default TitledSegment;