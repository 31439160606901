// Apollo imports
import {
  ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { SubscriptionClient } from "subscriptions-transport-ws";
import App from './routes';

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
const httpLink = new HttpLink({
  uri: `https://${window.location.host}/graphql/v1/graphql`,
  credentials: 'same-origin',
});

// Create a WebSocket link:
const subscriptionClient = new SubscriptionClient(`wss://${window.location.host}/graphql/v1/graphql`, { reconnect: true });
const wsLink = new WebSocketLink(subscriptionClient);
export const SubscriptionClientContext = React.createContext();

const errLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.extensions.code === 'start-failed' 
    || networkError?.extensions.code === 'validation-failed' 
    || graphQLErrors?.filter(
        err => err?.extensions?.code === 'access-denied'
      ).length > 0 
  ) {
    history.push(`/login?redirect_uri=${window.location.pathname.replace('/webapp', '')}`);
    return;
  }
  if (graphQLErrors == null)  return;
  else {
    const err = graphQLErrors[0];
    let message = `GRAPHQL ERROR: ${err.message}`;
    if (err.extensions && err.extensions.internal)  message += `\n${err.extensions.internal.error.message}`;
    window.alert(message);
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitterLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

// Mark scroll position for restoration on back
let prevLocation = window.location.pathname;
history.listen((location, action) => {
  if (prevLocation != null) sessionStorage.setItem(`scroll_${prevLocation}`, window.pageYOffset);
  if (action === 'POP') {
    const scrollY = location == null ? 0 : sessionStorage.getItem(`scroll_${location.pathname}`) || 0;
    window.scrollTo(0, scrollY);
  } else {
    window.scrollTo(0, 0);
  }
  prevLocation = location.pathname;
})

// Instantiate client
const client = new ApolloClient({
  link: from([errLink, splitterLink]),
  cache: new InMemoryCache()
});

client.defaultOptions = {
  query: { fetchPolicy: 'cache-and-network' },
  watchQuery: { fetchPolicy: 'cache-and-network' }
};

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={client}>
      <SubscriptionClientContext.Provider value={subscriptionClient}>
        <App />
      </SubscriptionClientContext.Provider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
);