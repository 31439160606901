import { useMutation } from '@apollo/client';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { useForm } from 'src/misc/hooks.jsx';
import * as QUERIES from './queries.js';
import { useHistory } from 'react-router-dom';

const DialogDuplicateTemplate = ({ idTemplateFrom, setIdTemplateFrom }) => {
  const history = useHistory();
  const initialValues = { notes: '' };
  const [m_duplicate_dial_template] = useMutation(QUERIES.m_duplicate_dial_template);
  const [formValues, setFormValues, , , formProps] = useForm(initialValues);
  const handleReset = () => { 
    setFormValues(initialValues); 
    setIdTemplateFrom(null); 
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await m_duplicate_dial_template({ variables: { ...formValues, id_from: idTemplateFrom } });
      window.alert('Template successfully duplicated.');
      history.push(`/production/dial_templates/${result.data.duplicate_dial_template.payload}`);
    } finally {
      setFormValues(initialValues);
      setIdTemplateFrom(null);
    }
  }
  return (
    <Modal dimmer="blurring" open={idTemplateFrom!=null} onClose={handleReset} size="mini">
      <Modal.Header>Duplicate template</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea label='Notes' { ...formProps.notes } type="text" required />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' onClick={handleReset} ><Icon name='remove' />Discard</Button>
        <Button color='green' onClick={handleSubmit} ><Icon name='checkmark' />Ok</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DialogDuplicateTemplate;