import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { UserContext } from "src/routes/index.jsx";
import PropertiesTable from "src/misc/PropertiesTable.jsx";

const Detail = ({ currency, line }) => {
  const me = React.useContext(UserContext);
  return (
    <Grid relaxed="very" celled="internally">
      <Grid.Row>
        <Grid.Column width={6}>
          <PropertiesTable
            tableProps={{ basic: 'very', celled: true, compact: 'very' }}
            keyWidth={6}
            items={[
              { key: 'Order #', value: <Link to={`/sales_orders/${line.id_sales_order}`}>{line.id_sales_order}</Link> },
              { key: 'Line #', value: line.line },
              line.id_invoice && { key: 'Invoice #', value: <Link to={`/invoices/${line.id_invoice}`}>{line.id_invoice}</Link> },
              line.company_documents && { key: 'Document customer', value: line.company_documents.name },
              line.company_label && { key: 'Label customer', value: line.company_label.name },
              line.end_customer_po && { key: 'End customer PO #', value: line.end_customer_po },
            ]}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <PropertiesTable
            tableProps={{ basic: 'very', celled: true, compact: 'very' }}
            items={[
              { key: 'Model code', value: line.inventory.model_code },
              line.customer_code && { key: 'Customer code', value: line.customer_code },
              (me.isSales || me.isAccounts || me.isOwner) && { key: 'Price', value: `${currency} ${line.price}` },
              { key: 'Quantity', value: line.quantity },
              line.notes && { key: 'Notes', value: line.notes },
            ]}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Detail;