import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

const SalesOrderRoute = () =>
  <>
    <Switch>
      <Route exact path="/sales_orders" component={List} />
      <Route path="/sales_orders/:id" component={Detail} />
    </Switch>
  </>

export default SalesOrderRoute;