import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Header, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import { all, isNumber } from 'src/misc/validators.js';
import * as yup from 'yup';
import * as QUERIES from './queries';

const DIMENSION_OPTIONS = [
  { id: '', value: '', text: '' },
  { id: 'length', value: 'length', text: 'length' },
  { id: 'area', value: 'area', text: 'area' },
  { id: 'volume', value: 'volume', text: 'volume' },
  { id: 'mass', value: 'mass', text: 'mass' },
  { id: 'time', value: 'time', text: 'time' },
  { id: 'electric_current', value: 'electric_current', text: 'electric_current' },
  { id: 'temperature', value: 'temperature', text: 'temperature' },
  { id: 'pressure', value: 'pressure', text: 'pressure' },
  { id: 'flow', value: 'flow', text: 'flow' },
];

const validateMappingArrayFromStr = str => (str === "") || str.split('\n').map(i => i.split('\t')).map(pair => pair.length === 2 && pair.map(isNumber).reduce(all, true)).reduce(all, true);
const parseMappingArrayFromStr = str => str === "" ? [] : str.split('\n').map(i => i.split('\t').map(i => Number(i)));
const formatArrayToStr = arr => Array.isArray(arr) ? arr.map(i => i.join('\t')).join('\n') : arr;

const makeUnitOptions = ({ id, name, notes }) => ({ key: id, value: id, text: name, description: notes });

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().nullable(),
  notes: yup.string().nullable(),
  dimension: yup.string().required().default('pressure'),
  id_unit_certificate: yup.string().nullable(),
  mapping: yup.string().default('').test('mapping-pair-check', '${path} must contain list of numerical pairs', validateMappingArrayFromStr), // eslint-disable-line
});

const UnitForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  const formDataQuery = useQuery(QUERIES.q_form);
  
  if (formDataQuery.loading || updateQuery?.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;


  const handleSubmit = async (fields) => {
    fields.mapping = parseMappingArrayFromStr(fields.mapping);
    fields.id_unit_certificate = fields.id_unit_certificate ?? null;
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [fields] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.unit_by_pk ?? validationSchema.default());
  initialValues.mapping = formatArrayToStr(initialValues.mapping);

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Unit`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="name" component={Form.Input} label="Name" />
              <SemanticField name="description" component={Form.Input} label="Description (appears on certificate)" />
              <SemanticField name="notes" component={Form.TextArea} label="Notes" />
              <SemanticField name="dimension" component={Form.Dropdown} fluid selection label="Dimension" options={DIMENSION_OPTIONS} />
              <SemanticField name="id_unit_certificate" component={Form.Dropdown} fluid selection clearable label="Certificate Unit" options={formDataQuery.data.unit.map(makeUnitOptions)} />
              <Header as="h5">Mapping<br/>(bar - unit)</Header>
              <SemanticField name="mapping" component={Form.TextArea} rows={12}
                style={{ MozTabSize: 24, tabSize: 24 }}
                onKeyDown={(e) => {
                  if(e.keyCode === 9 || e.which === 9){
                    e.preventDefault();
                    var s = e.target.selectionStart;
                    e.target.value = e.target.value.substring(0,e.target.selectionStart) + "\t" + e.target.value.substring(e.target.selectionEnd);
                    e.target.selectionEnd = s+1; 
                  }
                }}
              />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default UnitForm;