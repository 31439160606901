import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import DataTable from 'src/misc/DataTable';
import { UserContext } from 'src/routes/index.jsx';
import Form from './Form';
import * as QUERIES from './queries.js';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    '&>h2': {
      color: 'hsl(0, 0%, 40%)',
      fontWeight: 'bold',
    },
    '&>*': { margin: '32px 0' },
  }
})

const DialTemplateImageTable = ({ id_dial_template, images }) => {
  const classes = useStyles();
  const me = useContext(UserContext);
  const [m_delete] = useMutation(QUERIES.m_delete);
  const [formOpen, setFormOpen] = useState(null);
  return (
    <div className={classes.container}>
      <h2>Images</h2>
      <Form formOpen={formOpen} setFormOpen={setFormOpen} />
      <DataTable
        tableProps={{ celled: true, basic: 'very', compact: 'very' }}
        columns={[
          { key: 'cx', value: 'Center x (mm)' },
          { key: 'cy', value: 'Center y (mm)' },
          { key: 'w', value: 'Width (mm)' },
          { key: 'h', value: 'Height (mm)' },
          { key: 'angle', value: 'Rotation (deg)' },
          { key: 'image', value: 'Image' },
          { key: 'line_actions', value: '', width: 1, ignoreClick: true, hidden: !me.isDialPrinting },
        ]}
        rows={ images.map(image => {
          image.data = image.data.replace('\\x', '');
          var bytes = new Uint8Array(image.data.length / 2);
          for (var i = 0; i < image.data.length; i += 2) {
              bytes[i / 2] = parseInt(image.data.substring(i, i + 2), 16);
          }
          // Make a Blob from the bytes
          var blob = new Blob([bytes], {type: image.mime_type});
          return {
            ...image,
            key: image.id,
            image: <img style={{ maxWidth: '150px', maxHeight: '150px' }} alt="Preview" src={URL.createObjectURL(blob)} />,
            line_actions: (
              <div>
                <Button style={{ background: 'none' }} icon onClick={() => setFormOpen({ id: image.id })}>
                  <Icon name='pencil' />
                </Button>
                <Button style={{ background: 'none' }} 
                  icon onClick={() => window.confirm('Confirm deletion?') && m_delete({ variables: { id: image.id }})}
                >
                  <Icon color="red" name="trash" />
                </Button>
              </div>
            ),
          }
        })}
      />
      { me.isDialPrinting && 
        <Button secondary onClick={() => setFormOpen({ id_dial_template })} icon labelPosition='left'>
          <Icon name='add' />
          New image
        </Button>
      }
    </div>
  );
}

export default DialTemplateImageTable;