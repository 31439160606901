import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Header, Table } from 'semantic-ui-react';
import { UserContext } from '../index.jsx';
import * as QUERIES from './queries.js';

const ScannerPage = () => {
  const me = React.useContext(UserContext);
  const [gaugesScanned, setGaugesScanned] = useState([]);
  const [scanStage, setScanStage] = useState('');
  const [scanText, setScanText] = useState('');
  const [m_assembled] = useMutation(QUERIES.m_scan_assembled);
  const [m_angle_checked] = useMutation(QUERIES.m_scan_angle_checked);
  const [m_finished] = useMutation(QUERIES.m_scan_finished);
  const [m_checked] = useMutation(QUERIES.m_scan_checked);

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (scanText.match(/(.+)-(\d+)-(\d+)/)) {
        const result = scanStage === 'Assembly' ? await m_assembled({ variables: { id: scanText, id_employee: me.id }})
          : scanStage === 'Angle Check' ? await m_angle_checked({ variables: { id: scanText, id_employee: me.id }})
          : scanStage === 'Finishing' ? await m_finished({ variables: { id: scanText, id_employee: me.id }})
          : await m_checked({ variables: { id: scanText, id_employee: me.id }});
        if (result.error) window.alert(JSON.stringify(result.error, null, 2));
        else {
          setGaugesScanned([...gaugesScanned, scanText]);
          setScanText('');
        }
      }
    }, 1000); // This acts as a debounce, so that the function doesn't trigger while the QR scanner is sending keys
    return () => clearTimeout(timerId);
  }, [scanStage, scanText, setScanStage, setScanText, gaugesScanned, setGaugesScanned, me,
    m_assembled, m_angle_checked, m_finished, m_checked
  ]);

  useEffect(() => setGaugesScanned([]), [scanStage]); // Clear the scanned gauges list when the stage changes

  const stageOptions = [];
  if (me.isAssembly)  stageOptions.push({ key: 'Assembly', value: 'Assembly', text: 'Assembly' });
  if (me.isAssembly)  stageOptions.push({ key: 'Angle Check', value: 'Angle Check', text: 'Angle Check' });
  if (me.isFinishing)  stageOptions.push({ key: 'Finishing', value: 'Finishing', text: 'Finishing' });
  if (me.isQc)  stageOptions.push({ key: 'QC', value: 'QC', text: 'QC' });

  if (scanStage === '')  setScanStage(stageOptions[0].value);

  return (
    <Container>
      <Form>
        <Header as="h2">Scan Gauges</Header>
        <Form.Group>
          <Form.Dropdown label="Stage" value={scanStage} onChange={(e, { value }) => setScanStage(value)} selection options={stageOptions} width={3} />
          <Form.Input label="Scan gauge" value={scanText} onChange={(e, { value }) => setScanText(value)} width={9} />
        </Form.Group>
      </Form>
      <Table basic="very">
        <Table.Body>
          { gaugesScanned.map(gauge => 
            <Table.Row key={gauge}>
              <Table.Cell><Link to={`/sales_orders/${gauge.split('-')[0]}`}>{gauge}</Link></Table.Cell>
            </Table.Row>) 
          }
        </Table.Body>
      </Table>
    </Container>
  );
}

export default ScannerPage;