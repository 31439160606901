import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

const CompanyRoute = () =>
  <>
    <Switch>
      <Route exact path="/companies" component={List} />
      <Route exact path="/companies/:id" component={Detail} />
    </Switch>
  </>

export default CompanyRoute;