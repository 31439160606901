import gql from 'graphql-tag.macro';

export const m_delete = gql`
mutation delete_gauge_scale($id: Int!){
  delete_gauge_scale (where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_insert = gql`
mutation insert_gauge_scale ($objects: [gauge_scale_insert_input!]!){
  insert_gauge_scale(objects: $objects) {
    affected_rows
  },
}`;

export const m_update = gql`
mutation update_gauge_scale ($id: Int!, $_set: gauge_scale_set_input){
  update_gauge_scale (where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
}`;

export const q_update = gql`
query update_gauge_scale ($id: Int!) {
  gauge_scale_by_pk (id: $id) {
    id
    scale_number
    id_gauge_line
    id_scale
    multiplier
    id_unit
    is_ascending
    dial_multiplier
    label_text
    colorbands
    deadband_from
    deadband_to
  },
}`;

export const q_form = gql`
query gauge_scale_form {
  scale (order_by: { range_from: asc, range_to: asc, notes: asc }) {
    id
    notes
    major_markings
    minor_markings
    range_from
    range_to
  }

  unit {
    id
    name
    notes
  }
}`;