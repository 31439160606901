import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  cx: yup.number().required(),
  cy: yup.number().required(),
  d: yup.number().required(),
  start_angle: yup.number().required(),
  arc_angle: yup.number().required(),
  strokewidth: yup.number().required(),
  strokecolor: yup.string().required().default('#000000'),
  notes: yup.string().nullable(),
});

const DialTemplateArcForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.notes = fields.notes ?? null;
    fields.strokecolor = fields.strokecolor?.trim();
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [{ ...fields, id_dial_template: formOpen?.id_dial_template }] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template_arc_by_pk ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Arc`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="cx" component={Form.Input} label="Arc center X (mm)" type="number" />
              <SemanticField name="cy" component={Form.Input} label="Arc center Y (mm)" type="number" />
              <SemanticField name="d" component={Form.Input} label="Arc diameter (mm)" type="number" />
              <SemanticField name="start_angle" component={Form.Input} label="Arc start (deg)" type="number" />
              <SemanticField name="arc_angle" component={Form.Input} label="Arc angle (deg)" type="number" />
              <SemanticField name="strokewidth" component={Form.Input} label="Stroke width (mm)" type="number" />
              <SemanticField name="strokecolor" component={Form.Input} label="Stroke color" />
              <SemanticField name="notes" component={Form.TextArea} label="Notes"  />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default DialTemplateArcForm;