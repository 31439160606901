import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';

const ProductionUnitsRoute = () =>
  <>
    <Switch>
      <Route exact path="/production/units" component={List} />
    </Switch>
  </>

export default ProductionUnitsRoute;