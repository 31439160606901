import React, { Fragment } from 'react';
import hash from 'object-hash';

export const fragmentNewlines = a => a && a.split('\n').map((val, i) => (<Fragment key={i}>{val}<br /></Fragment>));

export const shallowDiff = (a, b) => {
  const keys = Array.from(new Set([...Object.keys(a), ...Object.keys(b)]));
  return keys.reduce((obj, key) => a[key] !== undefined && b[key] !== undefined && hash(a[key]) === hash(b[key]) ? obj : { ...obj, [key]: b[key] }, {});
};

export const stripTypename = input =>
  Array.isArray(input) // Check required since arrays are objects too
    ? input.map(val => stripTypename(val))
    : input && typeof input === 'object' // Check required since null is also an object
      ? Object.keys(input).reduce((obj, key) => key === '__typename' ? obj : { ...obj, [key]: stripTypename(input[key]) }, {})
      : input;

export const precisionRound = (number, precision) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}