import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Form } from 'semantic-ui-react';

const buf2hex = (buffer) => [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, '0')).join('');

const ImageUploadField = ({ name, label, width, fast=false }) => {
  const { setFieldValue, setFieldError } = useFormikContext();
  const [imgSrc, setImgSrc] = useState('');
  const [, meta] = useField(name);

  const handleImageUpload = async (event) => {
    var file = event.currentTarget.files[0];
    if (file == null) return;
    if (file.size > 100*1024)  {
      setImgSrc('');
      debugger;
      setFieldError(name, 'Max file size exceeded');
      return;
    } else if (!['image/jpeg', 'image/png'].includes(file.type)) {
      setImgSrc('');
      debugger;
      setFieldError(name, 'Bad file type');
      return;
    } else {
      var reader = new FileReader();
      reader.onload = async () => {
        const blob = await (await fetch(reader.result)).blob();
        const buf = await blob.arrayBuffer();
        setImgSrc(reader.result);
        setFieldValue(name, {
          data: '\\x'+buf2hex(buf),
          mime_type: file.type,
        });
        setFieldError(name, null);
      }
      reader.readAsDataURL(file);
    }
  };

  return (
    <Form.Field width={width}>
      <label>{label} (Max 100kB - jpg/png)</label>
      <Form.Input type="file" onChange={handleImageUpload} />
      {imgSrc !== '' && <img style={{ maxWidth: '150px', maxHeight: '150px' }} id={name} src={imgSrc} alt="Uploaded" />}
      {meta.error && <div style={{ color: '#9f3a38' }} >{meta.error}</div>}
    </Form.Field>
  );
}

export default ImageUploadField;