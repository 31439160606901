import { useMutation } from '@apollo/client';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { useForm } from 'src/misc/hooks.jsx';
import * as QUERIES from './queries';

const BulkInsertForm = ({ idGaugeLine, setIdGaugeLine, maxLineItem }) => {
  const initialValues = { tag_numbers: '', quantity: 1 };
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const [formValues, setFormValues, , , formProps] = useForm(initialValues);
  const handleReset = () => { 
    setFormValues(initialValues); 
    setIdGaugeLine(null); 
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const gauges = [];
      const tag_numbers = formValues.tag_numbers == null ? [] : formValues.tag_numbers.split(/\r?\n/);
      for (let i=0; i<formValues.quantity; i++) {
        const line_item = maxLineItem + 1 + i;
        gauges.push({ 
          id: `${idGaugeLine}-${line_item}`,
          id_gauge_line: idGaugeLine, 
          line_item,
          tag_number: tag_numbers.length > i ? tag_numbers[i] : undefined,
        });
      }
      await insertMutation({ variables: { objects: gauges } });
      window.alert("Added gauges");
    } finally {
      setFormValues(initialValues);
      setIdGaugeLine(null);
    }
  }
  return (
    <Modal dimmer="blurring" open={idGaugeLine!=null} onClose={handleReset} size="mini">
      <Modal.Header>Insert Gauges</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input label='Quantity' { ...formProps.quantity } type="number" step="1" required min="1" />
          <Form.TextArea label='Tag numbers' { ...formProps.tag_numbers } type="text" required />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' onClick={handleReset} ><Icon name='remove' />Discard</Button>
        <Button color='green' onClick={handleSubmit} ><Icon name='checkmark' />Ok</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default BulkInsertForm;