import { useMutation, useSubscription } from '@apollo/client';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useRouteMatch } from 'react-router';
import { Button, Header, Icon } from 'semantic-ui-react';
import DelayedLoader from 'src/misc/DelayedLoader';
import PropertiesTable from 'src/misc/PropertiesTable';
import TitledSegment from 'src/misc/TitledSegment';
import { UserContext } from 'src/routes/index.jsx';
import AddressDetails from '../address/Details';
import AddressForm from '../address/Form';
import CompanyForm from './Form';
import * as COMPANY_QUERIES from './queries.js';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  addressContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&>*': { 
      flex: '0 0 auto',
      marginRight: '32px',
      marginBottom: '16px',
      '&:not(button)': { width: '300px' },
    },
  },
  mainContainer: {
    '&>*': {
      padding: '3em 3em',
      '&>h2': {
        color: 'hsl(0, 0%, 40%)',
        fontWeight: 'bold',
        marginBottom: '1em',
      },
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});

const CompanyDetailsPage = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const me = React.useContext(UserContext);
  const classes = useStyles();
  const [companyFormOpen, setCompanyFormOpen] = useState(null);
  const [addressFormOpen, setAddressFormOpen] = useState(null);
  const [m_delete_company] = useMutation(COMPANY_QUERIES.m_delete, { variables: { id: match.params.id }});

  const { data, error } = useSubscription(COMPANY_QUERIES.s_detail, {
    variables: { id: match.params.id }
  })

  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`;
  const company = data.company_by_pk;

  return (
    <div className={classes.mainContainer}>
      <CompanyForm formOpen={companyFormOpen} setFormOpen={setCompanyFormOpen} />
      <AddressForm formOpen={addressFormOpen} setFormOpen={setAddressFormOpen} />
      <div className={classes.flexContainer}>
        <div className={classes.details}>
          <Header as="h2">{company.name}</Header>
          <PropertiesTable
            tableProps={{ basic: 'very', celled: true, compact: 'very' }}
            items={[
              { key: 'ID', value: company.id },
              { key: 'GSTIN', value: company.gstin },
              { key: 'TIN', value: company.tin },
              { key: 'Label name', value: company.label_name },
              { key: 'Label website', value: company.label_website },
              { key: 'Label template #', value: company.id_label_template },
              { key: 'Document name', value: company.document_name },
            ]}
          />
        </div>
        <div className={classes.actions}>
          <TitledSegment title='Actions' >
            { me.isSales && 
              <button onClick={() => setCompanyFormOpen({ id: company.id })}>
                <Icon name='edit' />
                Edit
              </button> 
            } 
            { me.isSales && 
              <button onClick={() => window.confirm('Confirm deletion?') && m_delete_company() && history.push('/companies')}>
                <Icon color="red" name="trash" />
                Delete
              </button>
            }
          </TitledSegment>
        </div>
      </div>
      <div>
        <h2>Addresses</h2>
        <div className={classes.addressContainer}>
          { company.addresses.map(address => <AddressDetails key={address.id} address={address} />) }
          { me.isSales && 
            <Button onClick={() => setAddressFormOpen({ id_company: company.id })} icon labelPosition='left'>
              <Icon name='add' />
              New address
            </Button>
          }
        </div>
      </div>
    </div>
  );
}

export default CompanyDetailsPage;