export const FONT_OPTIONS = [
  { key: 0, value: 'Helvetica-Bold', text: 'Helvetica-Bold' },
  { key: 1, value: 'Helvetica', text: 'Helvetica' },
  { key: 2, value: 'DroidSans-Bold', text: 'DroidSans-Bold' },
  { key: 3, value: 'DroidSans', text: 'DroidSans' },
  { key: 4, value: 'HelveticaUnicode-Bold', text: 'HelveticaUnicode-Bold' },
  { key: 5, value: 'HelveticaUnicode', text: 'HelveticaUnicode' },
  { key: 6, value: 'SimSun', text: 'SimSun' },
  { key: 7, value: 'Swiss911XCmBT', text: 'Swiss911XCmBT' },
  { key: 8, value: 'Swiss721LightCondensed', text: 'Swiss721LightCondensed' },
];

export const TEXT_ALIGNMENT_OPTIONS = [
  { key: 0, value: 'left', text: 'left' },
  { key: 1, value: 'center', text: 'center' },
  { key: 2, value: 'right', text: 'right' },
];