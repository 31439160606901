import gql from 'graphql-tag.macro';

export const m_delete = gql`
mutation delete_gauge($id: String!){
  delete_gauge (where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_insert = gql`
mutation insert_gauge ($objects: [gauge_insert_input!]!){
  insert_gauge(objects: $objects) {
    affected_rows
  },
}`;

export const m_update = gql`
mutation update_gauge ($id: String!, $_set: gauge_set_input){
  update_gauge (where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
}`;

export const q_update = gql`
query update_gauge ($id: String!) {
  gauge_by_pk (id: $id) {
    id
    tag_number
  },
}`;