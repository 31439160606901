import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Header, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers.jsx';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries.js';

const makeInventoryOptions = ({ id, model_code, description }) => ({ key: id, value: id, text: model_code, description });

const validationSchema = yup.object().shape({
  id_sales_order: yup.string().required(),
  line: yup.number().integer().required().min(1),
  id_inventory: yup.string().required(),
  price: yup.number().required().min(0),
  quantity: yup.number().integer().required().min(1),
  customer_code: yup.string().nullable(),
  end_customer_po: yup.string().nullable(),
  article_number: yup.string().nullable(),
  notes: yup.string().nullable(),
  label_notes: yup.string().nullable(),
});

const InventorySaleLineForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  const formDataQuery = useQuery(QUERIES.q_form);
  
  if (formDataQuery.loading || updateQuery?.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.id = fields.id ?? `${fields.id_sales_order}-${fields.line}`;
    fields.customer_code = fields.customer_code ?? null;
    fields.end_customer_po = fields.end_customer_po ?? null;
    fields.article_number = fields.article_number ?? null;
    fields.notes = fields.notes ?? null;
    fields.label_notes = fields.label_notes ?? null;
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [fields] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.inventory_sale_line_by_pk ?? validationSchema.default());
  initialValues.id_sales_order = initialValues.id_sales_order ?? formOpen?.id_sales_order;

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Inventory Line`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <Form.Group>
                <SemanticField name="id_sales_order" component={Form.Input} disabled label="Sales Order" width={3} />
                {!isUpdateForm && <SemanticField name="line" component={Form.Input} type="number" min="1" label="Line #" width={2} />}
                <SemanticField name="id_inventory" component={Form.Dropdown} width={8} fluid search selection label="Inventory Item" options={formDataQuery.data.inventory.map(makeInventoryOptions)} />
                <SemanticField name="price" component={Form.Input} type="number" min="0" step="0.01" label="Price" width={3} />
                <SemanticField name="quantity" component={Form.Input} type="number" min="0" label="Quantity" width={2} />
              </Form.Group>
              <Header as='h3'>Customer</Header>
              <Form.Group widths="equal">
                <SemanticField name="customer_code" component={Form.Input} label="Customer code" />
                <SemanticField name="end_customer_po" component={Form.Input} label="End customer PO" />
                <SemanticField name="article_number" component={Form.Input} label="Article number" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField name="notes" component={Form.TextArea} label="Notes" />
                <SemanticField name="label_notes" component={Form.TextArea} label="Label notes" />
              </Form.Group>

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default InventorySaleLineForm;