import gql from 'graphql-tag.macro';

const f_inventory_sale_line_scalar_details = gql`
  fragment inventory_sale_line_scalar_details_fragment on inventory_sale_line {
    id
    id_sales_order
    line
    id_invoice
    end_customer_po
    article_number
    quantity
    price
    customer_code
    label_notes
    notes
    id_inventory
  }
`;

export const m_insert = gql`
  mutation insert_inventory_sale_line($objects: [inventory_sale_line_insert_input!]!){
    insert_inventory_sale_line(objects: $objects) {
      affected_rows
    }
  },
  ${f_inventory_sale_line_scalar_details},
`;

export const m_delete = gql`
  mutation delete_inventory_sale_line($id: String!){
    delete_inventory_sale_line(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const m_update = gql`
  mutation update_inventory_sale_line($id: String!, $_set: inventory_sale_line_set_input){
    update_inventory_sale_line(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
    }
  },
  ${f_inventory_sale_line_scalar_details},
`;

// Query used for prefilling inventory_sale_line update form with current values
export const q_update = gql`
  query update_inventory_sale_line($id: String!) {
    inventory_sale_line_by_pk(id: $id) {
      ...inventory_sale_line_scalar_details_fragment
    } 
  },
  ${f_inventory_sale_line_scalar_details},
`;

export const q_form = gql`
query inventory_sale_line_form {
  inventory {
    id
    model_code
    description
  }
}`;

export const f_inventory_sale_line_details = gql`
  fragment inventory_sale_line_view_details_fragment on inventory_sale_line_view {
    id
    line
    id_sales_order
    invoice {
      id
      status
    }
    inventory {
      id
      model_code
    }
    customer_code
    end_customer_po
    price
    quantity
    amount
    notes
    qty_packed
  }
`;