import { useMutation, useQuery } from '@apollo/client';
import { FieldArray, Formik, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, List, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers.jsx';
import SelectField from 'src/misc/SelectField';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries.js';

const MODEL_CODE_REGEX = /^([\d\w]+)-([GS]+[MP]{0,2})-([ASBMPD])-((?:\d\.\d)|XX)-(2N|4N|4B|4T|8N|8B|ZZ)-([1-9])-(BF|EP|S4|S6|F4|F6|XX)-([FATLX])-([BEVTS])-((?:[1-8]A?[1-4])|0)-\((.*)\)-(.+)$/;

const ModelCodeField = (props) => {
  const { values: { gauge: { model_code }, dial, switches }, setFieldValue } = useFormikContext();

  useEffect(() => {
    const match = model_code?.match(MODEL_CODE_REGEX);
    if (match == null) return;
    
    let [ , , gauge_type, , , connection, , , , , switch_code, , ] = match;

    if (gauge_type.includes('G') && dial == null) setFieldValue('dial', {});
    else if (!gauge_type.includes('G') && dial != null) setFieldValue('dial', null);

    // PRESSURE CONNECTION
    if (connection !== 'ZZ') {
      const connection_diameter = connection[0] === '2' ? '1/2"' 
        : connection[0] === '4' ? '1/4"'
        : '1/8"';
      const connection_profile = connection[1] === 'N' ? 'NPT (F)'
        : connection[1] === 'B' ? 'BSP (F)'
        : 'BSPT (F)';
      setFieldValue('gauge.process_connection', `${connection_diameter} ${connection_profile}`);
    }

    let newSwitches = [...switches];
    if (switch_code === '0') newSwitches = [];
    else if (switch_code.match(/^[3478]/)) {
      while (newSwitches.length < 2)  newSwitches.push({ setpoint: 0, range_to: 0, is_ascending: true });
    } else {
      while (newSwitches.length < 1)  newSwitches.push({ setpoint: 0, range_to: 0, is_ascending: true });
    }
    if (switches.length !== newSwitches.length) setFieldValue('switches', newSwitches);
  }, [model_code, switches, dial, setFieldValue]);

  return <SemanticField component={Form.Input} {...props} />
}

const setGaugeLineDetails = (seriesList, fields) => {
  // Model code will always match since the field values have been already validated before this gets called
  const match = fields.gauge.model_code.match(MODEL_CODE_REGEX);  
  
  let [ , series, gauge_type, body_material, case_diameter, , porting, case_material, window_material, seal_material, switch_code, , options] = match;
  let has_flange;

  // SERIES
  series = seriesList.filter(({ name }) => name === series);
  if (series.length === 0) throw Error('No such gauge series');
  else series = series[0].id;
  
  // BODY MATERIAL
  if (body_material === 'A') body_material = 'Aluminium';
  if (body_material === 'S') body_material = 'SS316';
  if (body_material === 'B') body_material = 'Brass';
  if (body_material === 'M') body_material = 'Monel';
  if (body_material === 'P') body_material = 'Nylon';
  if (body_material === 'D') body_material = 'Acetal';

  // PORTING
  if (porting === '1') porting = 'Inline';
  if (porting === '2') porting = 'Back';
  if (porting === '3') porting = 'Bottom';
  if (porting === '4') porting = 'Bottom & Vent';
  if (porting === '5') porting = 'Inline & Vent';
  if (porting === '6') porting = 'Inline & Bottom';
  if (porting === '7') porting = 'Bottom, vent & inline';
  if (porting === '8') porting = 'Inline & Back';
  if (porting === '9') porting = 'Vent';

  // SEAL MATERIAL
  if (seal_material === 'B') seal_material = 'Buna-N';
  if (seal_material === 'E') seal_material = 'EPDM';
  if (seal_material === 'V') seal_material = 'Viton';
  if (seal_material === 'T') seal_material = 'Teflon';
  if (seal_material === 'S') seal_material = 'Silicone';

  fields.gauge = {
    ...fields.gauge,
    id_series: series,
    body_material,
    porting,
    seal_material,
    has_hi_port_mesh: false,
    is_reverse_ported: false,
    has_thick_body: false,
    has_rear_din_plug: false,
    has_metallic_switch_cover: false,
    is_nace: false,
    for_oxygen_service: false,
  }

  // GAUGE OPTIONS
  for (const option_code of options.split('-')) {
    if (option_code === 'F') fields.gauge.has_hi_port_mesh = true;
    if (option_code === 'G') fields.gauge.is_reverse_ported = true;
    if (option_code === 'K') fields.gauge.has_thick_body = true;
    if (option_code === 'P') fields.gauge.has_rear_din_plug = true;
    if (option_code === 'Q') fields.gauge.has_metallic_switch_cover = true;
    if (option_code === 'N') fields.gauge.is_nace = true;
    if (option_code === 'I') fields.gauge.for_oxygen_service = true;
  }

  if (gauge_type.includes('G')) {
    // CASE DIAMETER
    if (case_diameter === '2.0') case_diameter = 50;
    if (case_diameter === '2.5') case_diameter = 63;
    if (case_diameter === '3.5') case_diameter = 80;
    if (case_diameter === '4.0') case_diameter = 100;
    if (case_diameter === '4.4') case_diameter = 112;
    if (case_diameter === '4.5') case_diameter = 115;
    if (case_diameter === '6.0') case_diameter = 150;
    
    // FLANGE
    has_flange = case_material[0] === 'F';

    // CASE MATERIAL
    if (case_material === 'BF')             case_material = 'Bayonet';
    else if (case_material === 'EP')        case_material = 'Engineering Polymer';
    else {
      if (case_material.slice(-1) === '4')  case_material = 'SS304';
      else                                  case_material = 'SS316';
    }

    // WINDOW MATERIAL
    if (window_material === 'F') window_material = 'Float glass';
    if (window_material === 'A') window_material = 'Acrylic';
    if (window_material === 'T') window_material = 'Toughened glass';
    if (window_material === 'L') window_material = 'Safety glass';

    fields.dial = {
      ...fields.dial,
      id: 1,
      case_diameter,
      case_material,
      has_flange,
      window_material,
      has_follower_pointer: false,
    };

    // DIAL OPTIONS
    for (const option_code of options.split('-')) {
      if (option_code === 'A') fields.dial.filling_material = 'Glycerine';
      if (option_code === 'S') fields.dial.filling_material = 'Silicone';
      if (option_code === 'B') fields.dial.has_follower_pointer = true;
    }
  }

  if (switch_code === '0') fields.switches = [];
  else {
    let id_switch, type, has_din_plug = false, has_relay = false;

    // SWITCH DETAILS
    id_switch = switch_code.slice(-1);
    if (['1','2','2A','3','4','4A'].includes(switch_code.slice(0,-1)))  type = 'SPST';
    else                                                                type = 'SPDT';
    if (['1','3','5','7'].includes(switch_code.slice(0,-1)))            has_din_plug = true;
    if (['2A','4A'].includes(switch_code.slice(0,-1)))                  has_relay = true;

    fields.switches = fields.switches.map((sw, i) => ({ ...sw, id: i+1, id_switch, type, has_din_plug, has_relay }));
  }

  return fields;
}


const validationSchema = yup.object().shape({
  gauge: yup.object().shape({
    model_code: yup.string().required().matches(MODEL_CODE_REGEX),
    price: yup.number().required(),
    line: yup.number().required(),
    customer_code: yup.string().nullable(),
    end_customer_po: yup.string().nullable(),
    article_number: yup.string().nullable(),
    id_company_documents: yup.string().nullable(),
    id_company_label: yup.string().nullable(),
    notes: yup.string().nullable(),
    label_notes: yup.string().nullable(),
    max_pressure: yup.number().required(),
    max_temperature: yup.number().required(),
    max_accuracy: yup.number().required(),
    mounting: yup.string().nullable(),
    bracket: yup.string().nullable(),
    require_calibration_certificate: yup.bool().required().default(false),
    require_test_certificate: yup.bool().required().default(false),
    is_upside_down: yup.bool().required().default(false),
    process_connection: yup.string().required(),
    connection_adapter: yup.string().nullable(),
  }).required(),
  dial: yup.object().shape({
    id_dial_template: yup.string().nullable(), 
  }).nullable(),
  switches: yup.array().of(yup.object().shape({
    setpoint: yup.number().required(),
    range_to: yup.number().required(),
    id_unit: yup.string().required(),
    is_ascending: yup.bool().required().default(true),
  })).required().default([])
});

const GaugeLineForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [m_update_gauge_line] = useMutation(QUERIES.m_update_gauge_line);
  const [m_insert_gauge_line] = useMutation(QUERIES.m_insert_gauge_line);
  const [m_insert_dial] = useMutation(QUERIES.m_insert_dial);
  const [m_insert_gauge_switch] = useMutation(QUERIES.m_insert_gauge_switch);
  const [m_delete_dial] = useMutation(QUERIES.m_delete_dial);
  const [m_delete_gauge_switch] = useMutation(QUERIES.m_delete_gauge_switch);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  const formDataQuery = useQuery(QUERIES.q_form);
  const history = useHistory();
  
  if (formDataQuery.loading || updateQuery?.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.gauge.customer_code = fields.gauge.customer_code ?? null;
    fields.gauge.end_customer_po = fields.gauge.end_customer_po ?? null;
    fields.gauge.article_number = fields.gauge.article_number ?? null;
    fields.gauge.id_company_documents = fields.gauge.id_company_documents ?? null;
    fields.gauge.id_company_label = fields.gauge.id_company_label ?? null;
    fields.gauge.notes = fields.gauge.notes ?? null;
    fields.gauge.label_notes = fields.gauge.label_notes ?? null;
    fields.gauge.mounting = fields.gauge.mounting ?? null;
    fields.gauge.bracket = fields.gauge.bracket ?? null;
    fields.gauge.connection_adapter = fields.gauge.connection_adapter ?? null;
    try {
      fields = setGaugeLineDetails(formDataQuery.data.series, fields);
      const id = isUpdateForm ? formOpen?.id : `${formOpen?.id_sales_order}-${fields.gauge.line}`;
      if (isUpdateForm) {
        await m_update_gauge_line({ variables: { id, gauge: fields.gauge } });
        await m_delete_dial({ variables: { id } });
        await m_delete_gauge_switch({ variables: { id }});
      } else {
        await m_insert_gauge_line({ variables: { gauge: { id, id_sales_order: formOpen?.id_sales_order, ...fields.gauge } }});
      }
      if (fields.dial != null)
        await m_insert_dial({ variables: { dial: { id_gauge_line: id, ...fields.dial } }});
      if (fields.switches?.length > 0)
        await m_insert_gauge_switch({ variables: { gauge_switch: fields.switches.map(sw => ({ ...sw, id_gauge_line: id })) }});
      if (!isUpdateForm)  history.push(`/gauge_lines/${id}`);
      setFormOpen(null);
      onComplete && onComplete();
    } catch (e) {
      window.alert(e);
    }
  }

  const handleReset = () => {
    setFormOpen(null);
  }

  let initialValues;
  if (isUpdateForm) {
    const { dial, switches, ...gauge } = stripTypename(updateQuery?.data?.gauge_line_by_pk);
    initialValues = { gauge, dial, switches };
  } else initialValues = validationSchema.getDefaultFromShape()

  const customerOptions = formDataQuery.data.company.map(({ id, name }) => ({ key: id, value: id, label: name }));
  const mountingOptions = formDataQuery.data.mounting_options.map(({ mounting }, i) => ({ key: i, value: mounting, label: mounting }));
  const bracketOptions = formDataQuery.data.bracket_options.map(({ bracket }, i) => ({ key: i, value: bracket, label: bracket }));
  const unitOptions = formDataQuery.data.unit.map(({ id, name, notes }) => ({ key: id, value: id, label: name, subLabel: notes }));
  const dialTemplateOptions = formDataQuery.data.dial_template.map(({ id, notes }) => ({ key: id, value: id, label: notes }));

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Gauge Line`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <Form.Group>
                <ModelCodeField name="gauge.model_code" label="Model code" width={8} />
                <SemanticField fast name="gauge.price" component={Form.Input} type="number" min="0" step="0.01" label="Price" width={3} />
                {!isUpdateForm && <SemanticField fast name="gauge.line" component={Form.Input} type="number" label="Line #" width={3} />}
              </Form.Group>
              <Header as='h3'>Customer</Header>
              <Form.Group widths="equal">
                <SemanticField fast name="gauge.customer_code" component={Form.Input} label="Customer code" />
                <SemanticField fast name="gauge.end_customer_po" component={Form.Input} label="End customer PO" />
                <SemanticField fast name="gauge.article_number" component={Form.Input} label="Article number" />
              </Form.Group>
              <Form.Group widths="equal">
                <SelectField name="gauge.id_company_documents" fluid isSearchable isClearable label="Company name on documents" options={customerOptions} />
                <SelectField name="gauge.id_company_label" fluid isSearchable isClearable label="Company name on label" options={customerOptions} />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="gauge.notes" component={Form.TextArea} label="Notes" />
                <SemanticField fast name="gauge.label_notes" component={Form.TextArea} label="Label notes" />
              </Form.Group>
              <Header as='h3'>Gauge</Header>
              <Form.Group widths="equal">
                <SemanticField fast name="gauge.max_pressure" component={Form.Input} type="number" label="Max pressure (bar)" />
                <SemanticField fast name="gauge.max_temperature" component={Form.Input} type="number" label="Max temperature (°C)" />
                <SemanticField fast name="gauge.max_accuracy" component={Form.Input} type="number" label="Accuracy (%)" />
              </Form.Group>
              <Form.Group widths="equal">
                <SelectField fast name="gauge.mounting" fluid isSearchable isClearable isCreatable label="Mounting" options={mountingOptions} />
                <SelectField fast name="gauge.bracket" fluid isSearchable isClearable isCreatable label="Bracket" options={bracketOptions} />
                <SemanticField fast name="gauge.require_calibration_certificate" component={Form.Checkbox} label="Require calibration certificate?" />
                <SemanticField fast name="gauge.require_test_certificate" component={Form.Checkbox} label="Require test certificate?" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="gauge.is_upside_down" component={Form.Checkbox} label="Upside down pointer?" />
              </Form.Group>
              {formikProps.values?.gauge?.model_code?.includes('ZZ') && <Form.Group widths="equal">
                <SemanticField name="gauge.process_connection" component={Form.Input} label="Process connection" />
                <SemanticField name="gauge.connection_adapter" component={Form.Input} label='Connection adapter' />
              </Form.Group>}
              {formikProps.values.dial != null &&
                <>
                  <Header as='h3'>Dial</Header>
                  <Grid>
                    <Grid.Column width={8}>
                      <SelectField name="dial.id_dial_template" fluid isSearchable isClearable label="Dial template" options={dialTemplateOptions} />
                    </Grid.Column>
                  </Grid>
                </>
              }
              {formikProps.values.switches?.length > 0 &&
                <>
                  <Header as='h3'>Switches</Header>
                  <FieldArray name="switches">
                    <List>
                      {formikProps.values.switches.map((_, index) => (
                        <Form.Group key={index} as="li" widths="equal">
                          <SemanticField name={`switches.${index}.setpoint`} width={3} component={Form.Input} type="number" label="Setpoint" />
                          <SemanticField name={`switches.${index}.range_to`} width={3} component={Form.Input} type="number" label="Range To" />
                          <SelectField name={`switches.${index}.id_unit`} fluid isSearchable label="Units" options={unitOptions} />
                          <SemanticField name={`switches.${index}.is_ascending`} width={3} component={Form.Checkbox} label="Ascending?" />
                        </Form.Group>
                      ))} 
                    </List>
                  </FieldArray>
                </>
              }

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default GaugeLineForm;