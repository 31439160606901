import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const TRIM_FIELDS = ['name', 'website', 'gstin', 'email', 'phone_number', 'label_name', 'label_website', 'document_name'];

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  website: yup.string().nullable(),
  gstin: yup.string().nullable(),
  tin: yup.string().nullable(),
  email: yup.string().nullable(),
  phone_number: yup.string().nullable(),
  label_name: yup.string().required(),
  label_website: yup.string().required(),
  id_label_template: yup.number().required().default(0),
  document_name: yup.string().nullable(),
});

const CompanyForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    TRIM_FIELDS.forEach(field => fields[field] = fields[field] && fields[field].trim());
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [fields] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.company_by_pk ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Company`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="name" component={Form.Input} label="Name" />
              <SemanticField name="website" component={Form.Input} label="Website" />
              <SemanticField name="gstin" component={Form.Input} label="GSTIN (under Indian GST)" />
              <SemanticField name="tin" component={Form.Input} label="TIN (if not under GST)" />
              <SemanticField name="email" component={Form.Input} label="Email" />
              <SemanticField name="phone_number" component={Form.Input} label="Phone number" />
              <SemanticField name="label_name" component={Form.Input} label="Label name" />
              <SemanticField name="label_website" component={Form.Input} label="Label website" />
              <SemanticField name="id_label_template" component={Form.Input} label="Label template #" type="number" step="1" />
              <SemanticField name="document_name" component={Form.Input} label="Document name" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default CompanyForm;