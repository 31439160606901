import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import { all, isNumber } from 'src/misc/validators.js';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validateNumArrayFromStr = str => (str === "") || str.split(',').map(isNumber).reduce(all, true);
const parseNumArrayFromStr = str => str === "" ? [] : str.split(',').map(i => Number(i));
const formatArrayToStr = arr => Array.isArray(arr) ? arr.join(', ') : arr;

const validationSchema = yup.object().shape({
  range_from: yup.number().required(),
  range_to: yup.number().required(),
  notes: yup.string().nullable(),
  major_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  minor_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  text_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  callout_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  calibration_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  crosscheck_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
});

const ScaleForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.major_markings = parseNumArrayFromStr(fields.major_markings);
    fields.minor_markings = parseNumArrayFromStr(fields.minor_markings);
    fields.text_markings = parseNumArrayFromStr(fields.text_markings);
    fields.callout_markings = parseNumArrayFromStr(fields.callout_markings);
    fields.calibration_markings = parseNumArrayFromStr(fields.calibration_markings);
    fields.crosscheck_markings = parseNumArrayFromStr(fields.crosscheck_markings);
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [fields] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.scale_by_pk ?? validationSchema.default());
  initialValues.major_markings = formatArrayToStr(initialValues.major_markings);
  initialValues.minor_markings = formatArrayToStr(initialValues.minor_markings);
  initialValues.text_markings = formatArrayToStr(initialValues.text_markings);
  initialValues.callout_markings = formatArrayToStr(initialValues.callout_markings);
  initialValues.calibration_markings = formatArrayToStr(initialValues.calibration_markings);
  initialValues.crosscheck_markings = formatArrayToStr(initialValues.crosscheck_markings);

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Scale`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="range_from" width={8} component={Form.Input} label="Range from" type="number" />
              <SemanticField name="range_to" width={8} component={Form.Input} label="Range to" type="number" />
              <SemanticField name="notes" component={Form.TextArea} label="Notes" />
              <SemanticField name="major_markings" component={Form.TextArea} rows={1} label="Major markings" />
              <SemanticField name="minor_markings" component={Form.TextArea} rows={1} label="Minor markings" />
              <SemanticField name="text_markings" component={Form.TextArea} rows={1} label="Text markings" />
              <SemanticField name="callout_markings" component={Form.TextArea} rows={1} label="Callout markings" />
              <SemanticField name="calibration_markings" component={Form.TextArea} rows={1} label="Calibration markings" />
              <SemanticField name="crosscheck_markings" component={Form.TextArea} rows={1} label="Crosscheck markings" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default ScaleForm;