import gql from 'graphql-tag.macro';

const f_company_scalar_details = gql`
  fragment company_scalar_details_fragment on company {
    id
    name
    gstin
    tin
    website
    email
    phone_number
    label_name
    label_website
    id_label_template
    document_name
  }
`;

export const m_insert = gql`
mutation insert_company($objects: [company_insert_input!]!){
  insert_company(objects: $objects) {
    returning {
      id
    }
  },
  ${f_company_scalar_details},
}`;

export const m_delete = gql`
mutation delete_company($id: Int!){
  delete_company(where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_update = gql`
mutation update_company($id: Int!, $_set: company_set_input){
  update_company(where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
  ${f_company_scalar_details},
}`;

// Query used for prefilling company update form with current values
export const q_update = gql`
query update_company($id: Int!) {
  company_by_pk(id: $id) {
    ...company_scalar_details_fragment
  },
  ${f_company_scalar_details},
}`;

// Query used for loading company list
export const q_list = gql`
query companies($limit: Int, $offset: Int, $where: company_bool_exp, $order_by: [company_order_by!]) {
  list: company(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    id
    name
    addresses {
      city
      country
    }
  }
}`;

// Query used for loading company detail
export const s_detail = gql`
subscription company_by_pk($id: Int!) {
  company_by_pk(id: $id) {
    id
    name
    addresses (order_by: { id: asc }) {
      id
      id_company
      street
      city
      state
      district
      country
      zipcode
    }
    gstin
    tin
    label_name
    label_website
    id_label_template
    document_name
  } 
}`;