import numeral from 'numeral';
import React, { useContext, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { UserContext } from 'src/routes/index.jsx';
import DataTable from 'src/misc/DataTable';
import InventorySaleLineDetail from './Detail';

const InventorySaleLinesList = ({ lines, currency, line_actions, handleContextMenu }) => {
  const [selectedLine, setSelectedLine] = useState(null);
  const me = useContext(UserContext);
  const footerTotals = lines
    .map(line => [
      line.qty_packed,
      line.quantity,
      line.amount,
    ])
    .reduce((prevArr, curArr) => prevArr.map((val, i) => val + curArr[i]));

  return (
    <>
      <DataTable
        tableProps={{ fixed: true, celled: true, selectable: true, basic: 'very' }}
        rowProps={{ style: { cursor: 'pointer' }}}
        endReached={true}
        onClick={(_, i) => setSelectedLine(i)}
        handleContextMenu={handleContextMenu}
        columns={[
          { key: 'id_sales_order', value: 'OA #', width: 1 },
          { key: 'line', value: 'Line', width: 1 },
          { key: 'model_code', value: 'Model code', width: 4 },
          { key: 'id_invoice', value: 'Invoice', width: 2 },
          { key: 'packed', value: 'Packed', textAlign: 'right', type: 'percent' },
          { key: 'price', value: `Price (${currency})`, width: 1, textAlign: 'right', hidden: !me.isSales && !me.isAccounts && !me.isOwner  },
          { key: 'quantity', value: 'Quantity', textAlign: 'right', width: 1 },
          { key: 'amount', value: `Amount (${currency})`, width: 1, textAlign: 'right', hidden: !me.isSales && !me.isAccounts && !me.isOwner  },
          { key: 'line_actions', value: '', width: 1, ignoreClick: true, hidden: line_actions == null },
        ]}
        rows={lines.map(({ price, amount, quantity, ...line }) => ({
          key: `${line.id_sales_order}-${line.line}`,
          id_invoice: line.invoice ? line.invoice.id : null,
          line_actions: line_actions != null && line_actions(line),
          model_code: line.inventory.model_code,
          packed: { value: line.qty_packed, maxValue: quantity },
          quantity,
          price: numeral(price).format('0,0.00'),
          amount: numeral(amount).format('0,0.00'),
          ...line,
        }))}
        footer={[
          '',
          '',
          '',
          '',
          { value: footerTotals[0], maxValue: footerTotals[1] },
          '',
          footerTotals[1],
          numeral(footerTotals[2]).format('0,0.00'),
          '',
        ]}
      />
      <Modal
        size="large"
        open={selectedLine != null}
        onClose={() => setSelectedLine(null)}
      >
        <Modal.Header>Line Details</Modal.Header>
        <Modal.Content><InventorySaleLineDetail line={lines[selectedLine]} currency={currency} /></Modal.Content>
      </Modal>
    </>
  );
}

export default InventorySaleLinesList;
