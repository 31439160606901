import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { FieldArray, Formik, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Header, Icon, List, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers.jsx';
import PropertiesTable from 'src/misc/PropertiesTable.jsx';
import SelectField from 'src/misc/SelectField';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries.js';

const unescapeNewlines = a => a.replace(/\\n/g, '\n');
const mapTextToOption = (text, i) => ({ key: i, value: text, label: text });

const OPTIONS = {
  freight_carrier: [
    "As per your instructions",
    "Self Collect",
    "Gati Courier",
    "VRL Bus",
    "Shree Maruti Courier",
    "Avinash Carriers",
    "TCI XPS Courier",
    "Jaipur Golden Transport",
    "TNT Courier",
    "UPS Courier",
    "FedEx Courier",
    "DHL Courier",
  ],
  freight_terms: [
    "To Pay",
    "Paid",
    "Paid and charge extra",
    "Not Applicable",
  ],
  bank_terms: [
    "Your bank charges to your account. Our bank charges to our account.",
    "All charges including transfer charges to your account.",
  ],
  insurance_terms: [
    "To be arranged by buyer",
  ],
  trade_terms: [
    "Ex-Works Pune",
    "Ex-Works Pune. Payment by SWIFT\n\nSupply meant for export under LUT without payment of integrated tax.\nDuty drawback shipping bill.",
    "Ex-Works Pune. Payment by SWIFT\n\nSupply meant for export on payment of integrated tax.\nDuty drawback shipping bill.",
  ],
  freight_mode: [
    'Land',
    'Air',
    'Sea',
  ],
  einv_suptyp: [
    "B2B",
    "SEZWP",
    "SEZWOP",
    "EXPWP",
    "EXPWOP",
    "DEXP",
  ]
};

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  id_customer_billing: yup.string().required(),
  id_address_billing: yup.string().required(),
  id_customer_shipping: yup.string().required(),
  id_address_shipping: yup.string().required(),
  freight_terms: yup.string().required(),
  insurance_terms: yup.string().required(),
  payment_terms: yup.string().required(),
  bank_terms: yup.string().required(),
  trade_terms: yup.string().required(),
  freight_carrier: yup.string().required(),
  freight_mode: yup.string().required(),
  airwaybill_number: yup.string().nullable(),
  notes: yup.string().nullable(),
  invoice_date: yup.date().required().default(() => dayjs().format('YYYY-MM-DD')),
  discount: yup.number().required().min(0).default(0),
  discount_percent: yup.number().required().min(0).default(0),
  advance: yup.number().required().min(0).default(0),
  credit_days: yup.number().integer().required().min(0).default(0),
  freight_charge: yup.number().required().min(0).default(0),
  handling_charge: yup.number().required().min(0).default(0),
  pf_charge: yup.number().required().min(0).default(0),
  wire_charge: yup.number().required().min(0).default(0),
  id_currency: yup.string().required(),
  exchange_rate: yup.number().required().min(0).default(0),
  cgst: yup.number().required().min(0).default(0),
  sgst: yup.number().required().min(0).default(0),
  igst: yup.number().required().min(0).default(0),
  tcs: yup.number().required().min(0).default(0),
  einv_suptyp: yup.string().required().default('B2B'),
  einv_ewaybill_num: yup.string(),
  einv_regrev: yup.bool().required().default(false),
  einv_igstonintra: yup.bool().required().default(false),
  additional_charges: yup.array().of(yup.object().shape({
    amount: yup.number().required(),
    description: yup.string().required(),
    sac_code: yup.string().required(),
  })).default([]),
});

const PaymentTermsDropdown = ({ name, ...props }) => {
  const { values: { credit_days, advance }, setFieldValue, setFieldTouched } = useFormikContext();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const prevTermsRef = useRef({ credit_days, advance });

  useEffect(() => {
    const optionsSet = [
      `${Number(credit_days) ?? 0} days credit from date of invoice`, 
      `${Number(credit_days) ?? 0} days PDC from date of invoice`, 
      `100% due on delivery`, 
      `${advance}% advance before dispatch. Balance due after ${Number(credit_days) ?? 0} days from date of invoice`, 
      `${advance}% advance against proforma invoice before dispatch`, 
      `${advance}% advance against proforma invoice along with order`, 
      `${advance}% advance against proforma invoice along with order. Balance due against invoice before dispatch`, 
    ];
    if (prevTermsRef.current.credit_days !== credit_days || prevTermsRef.current.advance !== advance) {
      // Clear the field only if either credit_days or advance values are changed
      // This has the effect of skipping these statements on the initial run and running them only on subsequent calls
      // which happen only when either credit_days or advance fields change. No other dependency ever changes to cause
      // a retrigger of the effect.
      setFieldValue(name, null);
      setFieldTouched(name, false);
      prevTermsRef.current = { credit_days, advance };
    }
    setDropdownOptions(optionsSet);
  }, [credit_days, advance, name, setFieldTouched, setFieldValue, prevTermsRef]);

  return <SelectField {...props} name={name} fluid isCreatable options={dropdownOptions.map(mapTextToOption)} />
}

const AddressDropdown = ({ formData, id_customer, name, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const prevCustomerRef = useRef(id_customer);

  useEffect(() => {
    const customer = formData.company.filter(({ id }) => id === id_customer)[0];
    const addresses = customer?.addresses ?? [];
    setFilteredOptions(addresses.map(({ id, ...address }) => ({ 
      key: id, 
      value: id, 
      text: address.street, 
      content: (
        <PropertiesTable
          tableProps={{ basic: 'very', celled: true, compact: 'very' }}
          items={[
            { key: 'Street', value: address.street },
            { key: 'City', value: address.city },
            { key: 'Zipcode', value: address.zipcode },
            { key: 'District', value: address.district },
            { key: 'State', value: address.state },
            { key: 'Country', value: address.country },
          ]}
        />)
    })));
    if (prevCustomerRef.current !== id_customer) {
      // Set the address to the first entry only if the customer is changed
      // This has the effect of skipping these statements on the initial run and running them only on subsequent calls
      // which happen only when customer field changes. No other dependency ever changes to cause a retrigger of the effect.
      setFieldValue(name, addresses[0]?.id);
      prevCustomerRef.current = id_customer;
    }
  }, [formData, name, id_customer, setFilteredOptions, setFieldValue, prevCustomerRef]);

  return (
    <SemanticField name={name} component={Form.Dropdown} {...props} options={filteredOptions} />
  );
}

const InvoiceForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  const formDataQuery = useQuery(QUERIES.q_form);
  
  if (formDataQuery.loading || updateQuery?.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.freight_carrier = unescapeNewlines(fields.freight_carrier);
    fields.freight_terms = unescapeNewlines(fields.freight_terms);
    fields.insurance_terms = unescapeNewlines(fields.insurance_terms);
    fields.payment_terms = unescapeNewlines(fields.payment_terms);
    fields.bank_terms = unescapeNewlines(fields.bank_terms);
    fields.trade_terms = unescapeNewlines(fields.trade_terms);

    fields.notes = fields.notes ?? null;
    fields.airwaybill_number = fields.airwaybill_number ?? null;
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [fields] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.invoice_by_pk ?? validationSchema.default());

  const currencyOptions = formDataQuery.data.currency.map(({ id, name }) => ({ key: id, value: id, text: name }));
  const customerOptions = formDataQuery.data.company.map(({ id, name }) => ({ key: id, value: id, text: name }));

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Invoice`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <Form.Group>
                {!isUpdateForm && <SemanticField fast name="id" component={Form.Input} label="Invoice #" width={8} />}
                <SemanticField fast name="invoice_date" label="Invoice date" width={8} component={Form.Input} type="date" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="credit_days" component={Form.Input} type="number" label="Credit days" />
                <SemanticField fast name="advance" component={Form.Input} type="number" label="Advance (%)" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="discount_percent" component={Form.Input} type="number" label="Discount (%)" />
                <SemanticField fast name="discount" component={Form.Input} type="number" label="Discount amount" />
                <SemanticField fast name="id_currency" fluid selection label="Currency" options={currencyOptions} component={Form.Dropdown} />
                <SemanticField fast name="exchange_rate" component={Form.Input} type="number" label="Exchange rate (to INR)" />
              </Form.Group>
              <SemanticField fast name="notes" component={Form.TextArea} label="Notes" />
              <Header as='h2'>Customer details</Header>
              <Form.Group widths="equal">
                <SemanticField fast name="id_customer_billing" fluid search selection label="Billing Customer" options={customerOptions} component={Form.Dropdown} />
                <SemanticField fast name="id_customer_shipping" fluid search selection label="Shipping Customer" options={customerOptions} component={Form.Dropdown} />
              </Form.Group>
              <Form.Group widths="equal">
                <AddressDropdown name="id_address_billing" fluid search selection label="Billing Address" 
                  formData={formDataQuery.data} id_customer={formikProps.values.id_customer_billing}
                />
                <AddressDropdown name="id_address_shipping" fluid search selection label="Shipping Address" 
                  formData={formDataQuery.data} id_customer={formikProps.values.id_customer_shipping}
                />
              </Form.Group>
              <Header as='h2'>Transport details</Header>
              <Form.Group widths="equal">
                <SelectField fast name="freight_carrier" fluid isSearchable isCreatable label="Freight carrier" options={OPTIONS.freight_carrier.map(mapTextToOption)} />
                <SelectField fast name="freight_mode" fluid label="Freight mode" options={OPTIONS.freight_mode.map(mapTextToOption)} /> 
                <SemanticField fast name="airwaybill_number" component={Form.Input} label="Airwaybill #" />
              </Form.Group>
              <Header as='h2'>Terms</Header>
              <Form.Group>
                <SelectField fast name="freight_terms" width={3} fluid isSearchable isCreatable label="Freight terms" options={OPTIONS.freight_terms.map(mapTextToOption)} />
                <SelectField fast name="insurance_terms" width={3} fluid isSearchable isCreatable label="Insurance terms" options={OPTIONS.insurance_terms.map(mapTextToOption)} />
                <PaymentTermsDropdown name="payment_terms" width={4} fluid selection search allowAdditions label="Payment terms" />
                <SelectField fast name="bank_terms" width={3} fluid isSearchable isCreatable label="Bank terms" options={OPTIONS.bank_terms.map(mapTextToOption)} />
                <SelectField fast name="trade_terms" width={3} fluid isSearchable isCreatable label="Trade terms" options={OPTIONS.trade_terms.map(mapTextToOption)} />
              </Form.Group>
              <Header as='h2'>Charges</Header>
              <Form.Group widths="equal">
                <SemanticField fast name="freight_charge" type="number" component={Form.Input} label="Freight charge" />
                <SemanticField fast name="handling_charge" type="number" component={Form.Input} label="Handling charge" />
                <SemanticField fast name="pf_charge" type="number" component={Form.Input} label="P&F (%)" />
                <SemanticField fast name="wire_charge" type="number" component={Form.Input} label="Wire charge" />
              </Form.Group>
              <FieldArray name="additional_charges">
                {arrayHelpers => (
                  <List>
                    {formikProps.values.additional_charges.map((_, index) => (
                      <Form.Group key={index} as="li" widths="equal">
                        <SemanticField name={`additional_charges.${index}.description`} fluid component={Form.Input} label="Description" />
                        <SemanticField name={`additional_charges.${index}.amount`} fluid component={Form.Input} type="number" label="Amount" />
                        <SemanticField name={`additional_charges.${index}.sac_code`} fluid component={Form.Input} label="SAC code" />
                        <SemanticField name={`additional_charges.${index}.is_taxable`} fluid component={Form.Checkbox} label="Taxable?" />
                        <Button icon="delete" onClick={() => arrayHelpers.remove(index)} width={2} />
                      </Form.Group>
                    ))} 
                    <List.Item as="li">
                      <Button icon="plus" content="Add charge" labelPosition="left" onClick={() => arrayHelpers.push({ description: '', amount: 0, is_taxable: true })} />
                    </List.Item>
                  </List>
                )}
              </FieldArray>
              <Header as='h2'>E-invoice</Header>
              <Form.Group widths="equal">
                <SelectField fast name="einv_suptyp" fluid label="Supply type" options={OPTIONS.einv_suptyp.map(mapTextToOption)} />
                <SemanticField fast name="einv_regrev" component={Form.Checkbox} label="Reverse charge?" />
                <SemanticField fast name="einv_igstonintra" component={Form.Checkbox} label="IGST on Intra?" />
                <SemanticField fast name="einv_ewaybill_num" component={Form.Input} label="Ewaybill #" />
              </Form.Group>
              <Header as='h2'>Taxes</Header>
              <Form.Group widths="equal">
                <SemanticField fast name="cgst" component={Form.Input} type="number" label="CGST (%)" />
                <SemanticField fast name="sgst" component={Form.Input} type="number" label="SGST (%)" />
                <SemanticField fast name="igst" component={Form.Input} type="number" label="IGST (%)" />
                <SemanticField fast name="tcs" component={Form.Input} type="number" label="TCS (%)" />
              </Form.Group>

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default InvoiceForm;