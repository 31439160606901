import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import ImageUploadField from 'src/misc/ImageUploadField';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  cx: yup.number().required(),
  cy: yup.number().required(),
  w: yup.number().required(),
  h: yup.number().required(),
  angle: yup.number().default(0).min(-180).max(180).required(),
  image: yup.object().required(),
});

const DialTemplateImageForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async ({ image, ...fields }, { setFieldError }) => {
    if (!isUpdateForm && image.data == null) {
      setFieldError('image', 'Image file not selected');
      return;
    }
    fields.data = image.data;
    fields.mime_type = image.mime_type;
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [{ ...fields, id_dial_template: formOpen?.id_dial_template }] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  
  const initialValues = stripTypename(updateQuery?.data?.dial_template_image_by_pk ?? validationSchema.default());
  const { data, mime_type } = initialValues;
  initialValues.image = { data, mime_type };

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Image`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="cx" component={Form.Input} label="Center X (mm)" type="number" />
              <SemanticField name="cy" component={Form.Input} label="Center Y (mm)" type="number" />
              <SemanticField name="w" component={Form.Input} label="Width (mm)" type="number" />
              <SemanticField name="h" component={Form.Input} label="Height (mm)" type="number" />
              <SemanticField name="angle" component={Form.Input} label="Angle (deg)" type="number" />
              <ImageUploadField name="image" label="Image" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default DialTemplateImageForm;