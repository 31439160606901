import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BoxesRoute from './boxes';
import UnitsRoute from './units';
import ScalesRoute from './scales';
import SeriesRoute from './series';
import DialTemplatesRoute from './dial_templates';

const ProductionRoute = () =>
  <>
    <Switch>
      <Route path="/production/boxes" component={BoxesRoute} />
      <Route path="/production/units" component={UnitsRoute} />
      <Route path="/production/scales" component={ScalesRoute} />
      <Route path="/production/series" component={SeriesRoute} />
      <Route path="/production/dial_templates" component={DialTemplatesRoute} />
    </Switch>
  </>

export default ProductionRoute;