import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CollectionList from 'src/misc/CollectionList';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import Form from './Form';
import * as QUERIES from './queries.js';

const CompanyList = () => {
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(null);
  const me = React.useContext(UserContext);
  return (
    <>
      <Form formOpen={formOpen} setFormOpen={setFormOpen} 
        onComplete={data => history.push(`/companies/${data.insert_company.returning[0].id}`)} 
      />
      <CollectionList
        collectionName="Companies"
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`/companies/${id}`)}
        handleOpenCreateForm={me.isSales && (() => setFormOpen({ }))}
        tableColumns={[
          { key: 'name', value: 'NAME', width: '50%' },
          { key: 'city', value: 'CITY', sortable: false, width: '25%' },
          { key: 'country', value: 'COUNTRY', sortable: false, width: '25%' },
        ]}
        mapDataRowToTable={company => ({
          ...company,
          key: company.id,
          city: company.addresses.length > 0 && company.addresses[0].city,
          country: company.addresses.length > 0 && company.addresses[0].country,
        })}
        defaultSortOrder={[{ 'name': 'asc' }]}
        defaultFilters={{ name: '' }}
        filters={[
          { key: 'name', label: 'NAME', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { id: { _gte: 0 } };   // ID = -1 is an empty company kept as a workaround to blank labeling
          if (!isEmptyString(filterOptions.name))   queryFilters.name = { _ilike: `%${filterOptions.name}%` };
          return queryFilters;
        }}
      />
    </>
  )
};
export default CompanyList;