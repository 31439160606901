import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';

const ProductionSeriesRoute = () =>
  <>
    <Switch>
      <Route exact path="/production/series" component={List} />
    </Switch>
  </>

export default ProductionSeriesRoute;