import { useMutation, useSubscription } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { withRouter } from 'react-router';
import { Icon } from 'semantic-ui-react';
import DelayedLoader from 'src/misc/DelayedLoader';
import PropertiesTable from 'src/misc/PropertiesTable.jsx';
import TitledSegment from 'src/misc/TitledSegment.jsx';
import { UserContext } from 'src/routes/index.jsx';
import InventoryForm from './Form';
import * as QUERIES from './queries.js';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  addressContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&>*': { 
      flex: '0 0 auto',
      marginRight: '32px',
      marginBottom: '16px',
      '&:not(:last-child)': { width: '300px' },
    },
  },
  mainContainer: {
    '&>*': {
      padding: '3em 3em',
      '&>h2': {
        color: 'hsl(0, 0%, 40%)',
        fontWeight: 'bold',
        marginBottom: '1em',
      },
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});

const InventoryDetails = ({ history, match }) => {
  const me = useContext(UserContext);
  const classes = useStyles();
  const [inventoryFormOpen, setInventoryFormOpen] = useState(null);
  const [m_delete_inventory] = useMutation(QUERIES.m_delete, {  variables: { id: match.params.id }});
  const { data, error } = useSubscription(QUERIES.s_detail, {
    variables: { id: match.params.id }
  });
  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`;
  const inventory = data.inventory_view[0];

  return (
    <div className={classes.mainContainer}>
      <InventoryForm formOpen={inventoryFormOpen} setFormOpen={setInventoryFormOpen} />
      <div className={classes.flexContainer}>
        <div className={classes.details}>
          <PropertiesTable
            tableProps={{ basic: 'very', celled: true, compact: 'very' }}
            items={[
              { key: 'Model code', value: inventory.model_code },
              { key: 'Description', value: inventory.description },
              { key: 'HSN code', value: inventory.hsn_code },
              { key: 'Weight', value: inventory.weight },
              { key: 'Stock Quantity', value: inventory.qty_stock },
            ]}
          />
        </div>
        <div className={classes.actions}>
          <TitledSegment title='Actions' >
            { me.isSales && 
              <button onClick={() => setInventoryFormOpen({ id: inventory.id })}>
                <Icon name='edit' />
                Edit
              </button> 
            }
            { me.isSales && 
              <button onClick={() => window.confirm('Confirm deletion?') && m_delete_inventory() && history.push('/inventories')}>
                <Icon color="red" name="trash" />
                Delete
              </button>
            }
          </TitledSegment>
        </div>
      </div>
    </div>
  );
}

export default withRouter(InventoryDetails);