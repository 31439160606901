import dayjs from 'dayjs';
import numeral from 'numeral';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CollectionList from 'src/misc/CollectionList';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import Form from './Form';
import * as QUERIES from './queries.js';

const InvoiceList = () => {
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(null);
  const me = React.useContext(UserContext);
  return (
    <>
      <Form formOpen={formOpen} setFormOpen={setFormOpen} 
        onComplete={data => history.push(`/invoices/${data.insert_invoice.returning[0].id}`)} 
      />
      <CollectionList
        collectionName="Invoices"
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`/invoices/${id}`)}
        handleOpenCreateForm={me.isSales && (() => setFormOpen({ }))}
        tableColumns={[
          { key: 'invoice_date', value: 'DATE', width: '12%' },
          { key: 'id', value: 'ID', width: '12%' },
          { key: 'customer_billing.name', value: 'CUSTOMER', width: '30%' },
          { key: 'id_sales_orders', value: 'ORDERS', width: '12%' },
          { key: 'status', value: 'STATUS', width: '12%' },
          { key: 'quantity', value: 'QTY', width: '10%' },
          { key: 'total_amount', value: 'AMOUNT', width: '12%', textAlign: 'right', hidden: !me.isSales && !me.isAccounts && !me.isOwner  },
        ]}
        mapDataRowToTable={invoice => ({
          ...invoice,
          key: invoice.id,
          'customer_billing.name': invoice.customer_billing.name,
          id_sales_orders: invoice.id_sales_orders.join(', '),
          invoice_date: dayjs(invoice.invoice_date).format('DD MMM YY'),
          total_amount: `${invoice.currency.symbol} ${numeral(invoice.total_amount).format('0,0.00')}`,
        })}
        defaultSortOrder={[{ 'invoice_date': 'desc' }, { 'id': 'desc' }]}
        defaultFilters={{
          id: '', idSalesOrders: '', customer: '',
          region: 'All', status: me.isSales ? ['Sales', 'Production'] : ['Production'],
          dateFrom: '', dateTo: '',
        }}
        filters={[
          { key: 'id', label: 'ID', type: 'text' },
          { key: 'idSalesOrders', label: 'SALES ORDER', type: 'text' },
          { key: 'customer', label: 'CUSTOMER', type: 'text' },
          { key: 'region', label: 'REGION', type: 'radio', options: ['Export', 'Domestic', 'All'] },
          { 
            key: 'status', label: 'STATUS', type: 'select', multiple: true, 
            options: me.isSales ? ['Sales', 'Production', 'Dispatched'] : ['Production', 'Dispatched'] 
          },
          { key: 'dateFrom', label: 'DATE FROM', type: 'date' },
          { key: 'dateTo', label: 'DATE TO', type: 'date' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { 
            status: { _in: filterOptions.status },
            invoice_date: {} 
          };
          if (!isEmptyString(filterOptions.id))   queryFilters.id = { _ilike: `%${filterOptions.id}%` };
          if (!isEmptyString(filterOptions.customer))   queryFilters.customer_billing = { name: { _ilike: `%${filterOptions.customer}%` } };
          if (filterOptions.region === 'Domestic')  queryFilters.address_billing = { country: { _ilike: '%india%' } };
          else if (filterOptions.region === 'Export') queryFilters.address_billing = { country: { _nilike: '%india%' } };
          if (!isEmptyString(filterOptions.dateFrom)) queryFilters.invoice_date._gte = filterOptions.dateFrom;
          if (!isEmptyString(filterOptions.dateTo)) queryFilters.invoice_date._lte = filterOptions.dateTo;
          if (!isEmptyString(filterOptions.idSalesOrders)) queryFilters.id_sales_orders = { _has_keys_any : filterOptions.idSalesOrders };
          return queryFilters;
        }}
      />
    </>
  )
};
export default InvoiceList;