import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DispatchRoute from './dispatch';

const StatusMonitorRoute = () =>
  <>
    <Switch>
      <Route path="/status_monitor/dispatch" component={DispatchRoute} />
    </Switch>
  </>

export default StatusMonitorRoute;