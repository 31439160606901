import gql from 'graphql-tag.macro';
import { f_gauge_line_table_details } from '../gauge_line/queries';
import { f_inventory_sale_line_details } from '../inventory_sale_line/queries';

const f_invoice_details = gql`
  fragment invoice_details_fragment on invoice {
    id
    id_customer_billing
    id_address_billing
    id_customer_shipping
    id_address_shipping
    freight_terms
    insurance_terms
    payment_terms
    bank_terms
    trade_terms
    freight_carrier
    freight_mode
    airwaybill_number
    invoice_date
    discount_percent
    discount
    advance
    credit_days
    freight_charge
    handling_charge
    pf_charge
    wire_charge
    additional_charges
    id_currency
    exchange_rate
    cgst
    sgst
    igst
    tcs
    einv_suptyp
    einv_ewaybill_num
    einv_regrev
    einv_igstonintra
    notes
  }
`;

export const m_insert = gql`
  mutation insert_invoice($objects: [invoice_insert_input!]!){
    insert_invoice(objects: $objects) {
      returning {
        id
      }
    }
  }, 
  ${f_invoice_details},
`;

export const m_add_sales_order_line = gql`
  mutation add_sales_order_line_to_invoice($id_invoice: String!, $id_sales_order: String!, $line: smallint!) {
    update_gauge_line(where: {id_sales_order: {_eq: $id_sales_order}, line: {_eq: $line}, id_invoice: {_is_null: true}}, _set: {id_invoice: $id_invoice}) {
      affected_rows
    }
    update_inventory_sale_line(where: {id_sales_order: {_eq: $id_sales_order}, line: {_eq: $line}, id_invoice: {_is_null: true}}, _set: {id_invoice: $id_invoice}) {
      affected_rows
    }
  }
`;

export const m_add_sales_order = gql`
  mutation add_sales_order_to_invoice($id_invoice: String!, $id_sales_order: String!) {
    update_gauge_line(where: {id_sales_order: {_eq: $id_sales_order}, id_invoice: {_is_null: true}}, _set: {id_invoice: $id_invoice}) {
      affected_rows
    }
    update_inventory_sale_line(where: {id_sales_order: {_eq: $id_sales_order}, id_invoice: {_is_null: true}}, _set: {id_invoice: $id_invoice}) {
      affected_rows
    }
  }
`;

export const m_remove_sales_order_line = gql`
  mutation remove_sales_order_line_from_invoice($id: String!) {
    update_gauge_line(where: {id: {_eq: $id}}, _set: {id_invoice: null}) {
      affected_rows
    }
    update_inventory_sale_line(where: {id: {_eq: $id}}, _set: {id_invoice: null}) {
      affected_rows
    }
    delete_inner_box(where: {_or: [
      {gauges: {id_gauge_line: {_eq: $id}}}, 
      {inventory_sale_line_packings: {id_inventory_sale_line: {_eq: $id}}}
    ]}) {
      affected_rows
    }
    update_gauge(where: {id_gauge_line: {_eq: $id}}, _set: {id_packed_by: null, packed_at: null}) {
      affected_rows
    }
  }
`;

export const m_delete = gql`
  mutation delete_invoice($id: String!){
    delete_invoice(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const m_update = gql`
  mutation update_invoice($id: String!, $_set: invoice_set_input){
    update_invoice(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
    }
  },
  ${f_invoice_details},
`;

// Query used for prefilling invoice update form with current values
export const q_update = gql`
  query update_invoice($id: String!) {
    invoice_by_pk(id: $id) {
      ...invoice_details_fragment
    } 
  },
  ${f_invoice_details},
`;

// Query used for loading data required by invoice form (e.g. dropdown fields)
export const q_form = gql`
  query invoice_form {
    company {
      id
      name
      addresses {
        id
        street
        city
        district
        state
        country
        zipcode
      }
    }

    currency {
      id
      name
    }
  }
`;

// Query used for loading invoice detail
export const s_detail = gql`
  subscription invoice($id: String!) {
    invoice_view(where: { id: { _eq: $id }}) {
      id
      invoice_date
      freight_carrier
      freight_mode
      freight_terms
      insurance_terms
      payment_terms
      bank_terms
      trade_terms
      airwaybill_number
      credit_days
      notes
      status
      currency {
        id
        name
        symbol
      }
      exchange_rate
      customer_billing {
        id
        name
      }
      address_billing {
        id
        street
        city
        state
        district
        country
        zipcode
      }
      customer_shipping {
        id
        name
      }
      address_shipping {
        id
        street
        city
        state
        district
        country
        zipcode
      }
      quantity
      basic_amount
      taxable_amount
      total_amount
      advance
      all_charges
      cgst
      cgst_amount
      sgst
      sgst_amount
      igst
      igst_amount
      tcs
      tcs_amount
      einv_suptyp
      einv_irn
      einv_ewaybill_num
      einv_irn_date
      inventory_sale_lines (order_by: { id_sales_order: asc, line: asc }) {
        ...inventory_sale_line_view_details_fragment
      }
      gauge_lines (order_by: { id_sales_order: asc, line: asc }) {
        ...gauge_line_view_table_details_fragment
      }
    }
  }, 
  ${f_gauge_line_table_details},
  ${f_inventory_sale_line_details}
`;

// Query used for loading invoice list
export const q_list = gql`
query invoices($limit: Int!, $offset: Int!, $where: invoice_view_bool_exp, $order_by: [invoice_view_order_by!]) {
  list: invoice_view (limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    id
    customer_billing {
      id
      name
    }
    id_sales_orders
    status
    currency {
      id
      symbol
    }
    invoice_date
    total_amount
    quantity
  } 
}`;