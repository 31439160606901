import { useMutation, useSubscription } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Grid, Header, Icon } from 'semantic-ui-react';
import DelayedLoader from 'src/misc/DelayedLoader';
import { fragmentNewlines } from "src/misc/helpers";
import PropertiesTable from "src/misc/PropertiesTable";
import TitledSegment from 'src/misc/TitledSegment';
import { UserContext } from 'src/routes/index.jsx';
import DialTemplateArcTable from './arc/Table';
import DialTemplateCurvedTextTable from './curved_text/Table';
import DialogDuplicateTemplate from './DialogDuplicateTemplate';
import Form from './Form';
import DialTemplateImageTable from './image/Table';
import DialTemplateLineTable from './line/Table';
import * as QUERIES from './queries.js';
import DialTemplateScaleTable from './scale/Table';
import DialTemplateTextTable from './text/Table';


const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  mainContainer: {
    '&>*': {
      padding: '3em 3em',
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});

const Detail = ({ match }) => {
  const me = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(null);
  const [duplicateDialogTemplateId, setDuplicateDialogTemplateId] = useState(null);
  const [iframeKey, setIFrameKey] = useState(0);
  const [previewGaugeId, setPreviewGaugeId] = useState();

  const { data, error } = useSubscription(QUERIES.s_detail, { variables: { id: match.params.id }});
  const [m_delete_dial_template] = useMutation(QUERIES.m_delete, { variables: { id: match.params.id }});
  
  useEffect(() => {
    setIFrameKey(state => state + 1);
  }, [data]);

  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  const dial_template = data.dial_template_by_pk;
  
  return (
    <div className={classes.mainContainer}>
      <DialogDuplicateTemplate idTemplateFrom={duplicateDialogTemplateId} setIdTemplateFrom={setDuplicateDialogTemplateId} />
      <Form formOpen={formOpen} setFormOpen={setFormOpen} />
      <div className={classes.flexContainer}>
        <div className={classes.details}>
          <Grid celled='internally'>
            <Grid.Row>
              <Grid.Column width={8}>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Dial notes', value: <p>{fragmentNewlines(dial_template.notes)}</p> },
                    { key: 'Dial diameter (mm)', value: dial_template.d },
                    { key: 'Pinion hole Y offset (mm)', value: dial_template.pinion_y_offset },
                    { key: 'Foreground color', value: dial_template.fg_color },
                    { key: 'Background color', value: dial_template.bg_color },
                    { key: 'Square markings', value: dial_template.has_square_markings ? '✔️' : '✘' },
                    { key: 'Decimal point commas', value: dial_template.has_decimal_commas ? '✔️' : '✘' },
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as='h3'>Unit text</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'X (mm)', value: dial_template.unit_text_x },
                    { key: 'Y (mm)', value: dial_template.unit_text_y },
                    { key: 'Angle (deg)', value: dial_template.unit_text_angle },
                    { key: 'Font name', value: dial_template.unit_text_fontname },
                    { key: 'Font size (mm)', value: dial_template.unit_text_fontsize },
                    { key: 'Enable drawing combined', value: dial_template.unit_text_draw ? '✔️' : '✘' },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as='h3'>Porting</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'X (mm)', value: dial_template.porting_x },
                    { key: 'Y (mm)', value: dial_template.porting_y },
                    { key: 'Diameter (mm)', value: dial_template.porting_d },
                    { key: 'Arrow width (mm)', value: dial_template.porting_arrow_w },
                    { key: 'Arrow height (mm)', value: dial_template.porting_arrow_h },
                    { key: 'Arrow Y (mm)', value: dial_template.porting_arrow_y },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className={classes.actions}>
          <TitledSegment title='Actions' >
            {me.isDialPrinting && <button onClick={() => { setDuplicateDialogTemplateId(match.params.id); }}>Duplicate</button>}
            {me.isDialPrinting && <button onClick={() => { setFormOpen({ id: dial_template.id }); }}><Icon name='edit' />Edit</button>}
            {me.isDialPrinting && <button 
              onClick={() => window.confirm('Confirm deletion?') && m_delete_dial_template() && history.push('/production/dial_templates')}
            ><Icon color="red" name="trash" />Delete</button>}
          </TitledSegment>
          <input value={previewGaugeId} onChange={e => setPreviewGaugeId(e.target.value)} />
          {previewGaugeId != null && previewGaugeId !== "" &&
            <iframe title="Dial preview" key={iframeKey} src={`/pdf/gauges/${previewGaugeId}/dial`} height="320px" />
          }
        </div>
      </div>
      <DialTemplateArcTable id_dial_template={dial_template.id} arcs={dial_template.arcs} />
      <DialTemplateTextTable id_dial_template={dial_template.id} texts={dial_template.texts} />
      <DialTemplateCurvedTextTable id_dial_template={dial_template.id} texts={dial_template.curved_texts} />
      <DialTemplateLineTable id_dial_template={dial_template.id} lines={dial_template.lines} />
      <DialTemplateImageTable id_dial_template={dial_template.id} images={dial_template.images} />
      <DialTemplateScaleTable id_dial_template={dial_template.id} scales={dial_template.scales} />
    </div>
  );   
}


export default withRouter(Detail);