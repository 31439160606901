import { useMutation, useQuery } from '@apollo/client';
import { FieldArray, Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, List, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SelectField from 'src/misc/SelectField';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  scale_number: yup.number().required(),
  id_scale: yup.string().required(),
  multiplier: yup.number().required(),
  dial_multiplier: yup.number().required(),
  label_text: yup.string().nullable(),
  deadband_from: yup.number().required(),
  deadband_to: yup.number().required(),
  id_unit: yup.string().required(),
  is_ascending: yup.bool().default(true),
  colorbands: yup.array().of(yup.object().shape({
    from: yup.number().required(),
    to: yup.number().required(),
    color: yup.string().required().default('#000000'),
    hidden: yup.bool().default(false),
  })).default([]),
});

const GaugeLineScaleForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  const formDataQuery = useQuery(QUERIES.q_form);
  
  if (formDataQuery.loading || (isUpdateForm && updateQuery.loading)) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async ({ scale, unit, ...fields }) => {
    fields.colorbands.forEach(band => band.color = band.color.trim());
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [{ ...fields, id_gauge_line: formOpen?.id_gauge_line }] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  
  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.gauge_scale_by_pk ?? validationSchema.default());
  const unitOptions = formDataQuery.data.unit.map(({ id, name, notes }) => ({ key: id, value: id, label: name, subLabel: notes }));

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Scale`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="scale_number" component={Form.Input} label="Scale number" />
              <SelectField fast name="id_scale" fluid isSearchable label="Scale" options={formDataQuery.data.scale} 
                styles={{ valueContainer: provided => ({ ...provided, minHeight: '100px' }) }}
                formatOptionLabel={scale => (
                  <table>
                    <tbody>
                      <tr><td style={{ width: '10em' }} >{`Range (${scale.range_from} - ${scale.range_to})`}</td><td>{scale.notes}</td></tr>
                      <tr><td style={{ width: '10em' }} >Major markings</td><td>{scale.major_markings.join(', ')}</td></tr>
                      <tr><td style={{ width: '10em' }} >Minor markings</td><td>{scale.minor_markings.join(', ')}</td></tr>
                    </tbody>
                  </table>
                )}
                filterOption={(opt, input) => input == null || opt.data.notes == null || opt.data.notes.toLowerCase().includes(input.toLowerCase())}
                getOptionValue={scale => scale.id}
              />
              <SemanticField name="multiplier" component={Form.Input} label="Multiplier" />
              <SemanticField name="dial_multiplier" component={Form.Input} label="Dial multiplier (Values divided by this only for displaying on scale)" />
              <SemanticField name="label_text" component={Form.Input} label="Optional text label to be printed to the left of the scale" />
              <Form.Group widths="equal">
                <SemanticField name="deadband_from" component={Form.Input} label="Deadband from (deg)" />
                <SemanticField name="deadband_to" component={Form.Input} label="Deadband to (deg)" />
              </Form.Group>
              <SelectField fast name="id_unit" fluid isSearchable label="Unit" options={unitOptions} />
              <SemanticField name="is_ascending" component={Form.Checkbox} label="Ascending calibrated?" />
              <FieldArray name="colorbands">
                {arrayHelpers => (
                  <List>
                    {formikProps.values.colorbands.map((_, index) => (
                      <Form.Group key={index} as="li" widths="equal">
                        <SemanticField name={`colorbands.${index}.from`} fluid component={Form.Input} type="number" label="From" />
                        <SemanticField name={`colorbands.${index}.to`} fluid component={Form.Input} type="number" label="To" />
                        <SemanticField name={`colorbands.${index}.color`} fluid component={Form.Input} label="Hexadecimal Color" />
                        <SemanticField name={`colorbands.${index}.hidden`} fluid component={Form.Checkbox} label="Hide from OA" />
                        <Button icon="delete" onClick={() => arrayHelpers.remove(index)} width={2} />
                      </Form.Group>
                    ))} 
                    <List.Item as="li">
                      <Button icon="plus" content="Add colorband" labelPosition="left" onClick={() => arrayHelpers.push({})} />
                    </List.Item>
                  </List>
                )}
              </FieldArray>

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default GaugeLineScaleForm;