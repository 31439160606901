import dayjs from 'dayjs';
import numeral from 'numeral';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CollectionList from 'src/misc/CollectionList';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import Form from './Form';
import * as QUERIES from './queries.js';

const SalesOrderList = () => {
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(null);
  const me = React.useContext(UserContext);
  return (
    <>
      <Form formOpen={formOpen} setFormOpen={setFormOpen} 
        onComplete={data => history.push(`/sales_orders/${data.insert_sales_order.returning[0].id}`)} 
      />
      <CollectionList
        collectionName="Sales Orders"
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`/sales_orders/${id}`)}
        handleOpenCreateForm={me.isSales && (() => setFormOpen({ }))}
        tableColumns={[
          { key: 'order_date', value: 'DATE', width: '8%' },
          { key: 'id', value: 'OA #', width: '8%' },
          { key: 'customer.name', value: 'CUSTOMER', width: '21%' },
          { key: 'purchase_order_date', value: 'PO DATE', width: '8%' },
          { key: 'id_purchase_order', value: 'PO #', width: '15%' },
          { key: 'id_invoices', value: 'INVOICES', width: '15%' },
          { key: 'status', value: 'STATUS', width: '10%' },
          { key: 'quantity', value: 'QTY', width: '5%' },
          { key: 'basic_amount', value: 'AMOUNT', width: '10%', textAlign: 'right', hidden: !me.isSales && !me.isAccounts && !me.isOwner  },
        ]}
        mapDataRowToTable={sales_order => ({
          ...sales_order,
          key: sales_order.id,
          'customer.name': sales_order.customer.name,
          id_invoices: sales_order.id_invoices.join(', '),
          order_date: dayjs(sales_order.order_date).format('DD MMM YY'),
          purchase_order_date: dayjs(sales_order.purchase_order_date).format('DD MMM YY'),
          basic_amount: `${sales_order.currency.symbol} ${numeral(sales_order.basic_amount).format('0,0.00')}`,
        })}
        defaultSortOrder={[{ 'order_date': 'desc' }, { 'id': 'desc' }]}
        defaultFilters={{
          id: '', idPurchaseOrder: '', idInvoices: '', customer: '', series: '',
          region: 'All', status: ['Active','Shortclosed','Fulfilled'],
          orderDateFrom: '', orderDateTo: '', poDateFrom: '', poDateTo: '',
        }}
        filters={[
          { key: 'id', label: 'OA #', type: 'text' },
          { key: 'idPurchaseOrder', label: 'PO #', type: 'text' },
          { key: 'idInvoices', label: 'INVOICE', type: 'text' },
          { key: 'customer', label: 'CUSTOMER', type: 'text' },
          { key: 'series', label: 'SERIES', type: 'text' },
          { key: 'region', label: 'REGION', type: 'radio', options: ['Export', 'Domestic', 'All'] },
          { 
            key: 'status', label: 'STATUS', type: 'select', multiple: true, 
            options: ['Active','Shortclosed','Fulfilled'], 
          },
          { key: 'orderDateFrom', label: 'ORDER DATE FROM', type: 'date' },
          { key: 'orderDateTo', label: 'ORDER DATE TO', type: 'date' },
          { key: 'poDateFrom', label: 'PO DATE FROM', type: 'date' },
          { key: 'poDateTo', label: 'PO DATE TO', type: 'date' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { 
            status: { _in: filterOptions.status },
            order_date: {}, purchase_order_date: {},
          };
          if (!isEmptyString(filterOptions.id))   queryFilters.id = { _ilike: `%${filterOptions.id}%` };
          if (!isEmptyString(filterOptions.customer))   queryFilters.customer = { name: { _ilike: `%${filterOptions.customer}%` } };
          if (!isEmptyString(filterOptions.series))   queryFilters.gauge_lines = { series: { name: { _ilike: `%${filterOptions.series}%` }}};
          if (filterOptions.region === 'Domestic')  queryFilters.address = { country: { _ilike: '%india%' } };
          else if (filterOptions.region === 'Exports') queryFilters.address = { country: { _nilike: '%india%' } };
          if (!isEmptyString(filterOptions.orderDateFrom)) queryFilters.order_date._gte = filterOptions.orderDateFrom;
          if (!isEmptyString(filterOptions.orderDateTo)) queryFilters.order_date._lte = filterOptions.orderDateTo;
          if (!isEmptyString(filterOptions.idPurchaseOrder))   queryFilters.id_purchase_order = { _ilike: `%${filterOptions.idPurchaseOrder}%` };
          if (!isEmptyString(filterOptions.poDateFrom)) queryFilters.purchase_order_date._gte = filterOptions.poDateFrom;
          if (!isEmptyString(filterOptions.poDateTo)) queryFilters.purchase_order_date._lte = filterOptions.poDateTo;
          if (!isEmptyString(filterOptions.idInvoices)) queryFilters.id_invoices = { _has_keys_any : filterOptions.idInvoices };
          return queryFilters;
        }}
      />
    </>
  )
};
export default SalesOrderList;