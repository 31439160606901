import gql from 'graphql-tag.macro';

const f_series_scalar_details = gql`
  fragment series_scalar_details_fragment on series {
    id
    name
    type
    ip_code
    max_accuracy
    max_temperature
    max_pressure
    has_dual_dials
  }
`;

export const m_insert = gql`
mutation insert_series($objects: [series_insert_input!]!){
  insert_series(objects: $objects) {
    affected_rows
  },
  ${f_series_scalar_details},
}`;

export const m_delete = gql`
mutation delete_series($id: Int!){
  delete_series(where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_update = gql`
mutation update_series($id: Int!, $_set: series_set_input){
  update_series(where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
  ${f_series_scalar_details},
}`;

// Query used for prefilling series update form with current values
export const q_update = gql`
query update_series($id: Int!) {
  series_by_pk(id: $id) {
    ...series_scalar_details_fragment
  },
  ${f_series_scalar_details},
}`;

// Query used for loading series list
export const q_list = gql`
query seriess($limit: Int, $offset: Int, $where: series_bool_exp, $order_by: [series_order_by!]) {
  list: series(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    ...series_scalar_details_fragment
  },
  ${f_series_scalar_details}
}`;