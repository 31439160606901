import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CollectionList from 'src/misc/CollectionList';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import Form from './Form';
import * as QUERIES from './queries.js';

const DialTemplateList = () => {
  const history = useHistory();
  const [formOpen, setFormOpen] = useState(null);
  const me = React.useContext(UserContext);
  return (
    <>
      <Form formOpen={formOpen} setFormOpen={setFormOpen} 
        onComplete={data => history.push(`/production/dial_templates/${data.insert_dial_template.returning[0].id}`)} 
      />
      <CollectionList
        collectionName="Dial Templates"
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`/production/dial_templates/${id}`)}
        handleOpenCreateForm={me.isDialPrinting && (() => setFormOpen({ }))}
        tableColumns={[
          { key: 'd', value: 'DIAL (MM)', width: '20%' },
          { key: 'notes', value: 'NOTES', width: '80%' },
        ]}
        mapDataRowToTable={dial_template => ({
          ...dial_template,
          key: dial_template.id,
        })}
        defaultSortOrder={[{ notes: 'asc' }, { d: 'asc' }]}
        defaultFilters={{ notes: '' }}
        filters={[
          { key: 'notes', label: 'NOTES', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { };
          if (!isEmptyString(filterOptions.notes))   queryFilters.notes = { _ilike: `%${filterOptions.notes}%` };
          return queryFilters;
        }}
      />
    </>
  )
};
export default DialTemplateList;