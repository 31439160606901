import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Table';
import Detail from './Detail';

const GaugeLineRoute = () =>
  <>
    <Switch>
      <Route exact path="/gauge_lines" component={List} />
      <Route exact path="/gauge_lines/:id" component={Detail} />
    </Switch>
  </>

export default GaugeLineRoute;