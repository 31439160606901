import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const TRIM_FIELDS = ['city', 'district', 'state', 'zipcode', 'country'];

const continentOptions = [
  { key: "Africa", value: `Africa`, text: `Africa` },
  { key: "Antarctica", value: `Antarctica`, text: `Antarctica` },
  { key: "Asia", value: `Asia`, text: `Asia` },
  { key: "Australia", value: `Australia`, text: `Australia` },
  { key: "Europe", value: `Europe`, text: `Europe` },
  { key: "North America", value: `North America`, text: `North America` },
  { key: "South America", value: `South America`, text: `South America` },
]

const validationSchema = yup.object().shape({
  street: yup.string().required(),
  city: yup.string().nullable(),
  district: yup.string().nullable(),
  state: yup.string().nullable(),
  zipcode: yup.string().nullable(),
  country: yup.string().required(),
  continent: yup.string().required(),
});

const AddressForm = ({ formOpen, setFormOpen, onComplete }) => {
  const isUpdateForm = formOpen?.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [insertMutation] = useMutation(QUERIES.m_insert);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: formOpen?.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    TRIM_FIELDS.forEach(field => fields[field] = fields[field] && fields[field].trim());
    const { data } = isUpdateForm
      ? await updateMutation({ variables: { id: formOpen?.id , _set: fields }})
      : await insertMutation({ variables: { objects: [{ ...fields, id_company: formOpen?.id_company }] } })
    setFormOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setFormOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.address_by_pk ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={formOpen!=null} closeOnDimmerClick={false}>
      <Modal.Header>{`${isUpdateForm ? 'Update' : 'Insert'} Address`}</Modal.Header>
      <Modal.Content>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="street" component={Form.TextArea} label="Street" />
              <SemanticField name="city" component={Form.Input} label="City" />
              <SemanticField name="district" component={Form.Input} label="District" />
              <SemanticField name="state" component={Form.Input} label="State" />
              <SemanticField name="zipcode" component={Form.Input} label="Zipcode" />
              <SemanticField name="country" component={Form.Input} label="Country" />
              <SemanticField name="continent" fluid selection label="Continent" component={Form.Dropdown} options={continentOptions} />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default AddressForm;