import React from 'react';
import { Field, FastField } from 'formik';
import { Form } from 'semantic-ui-react';

const SemanticField = ({ component, fast=false, ...fieldProps }) => {
  const { showErrorsInline, ...rest } = fieldProps;
  const FieldComponent = fast ? FastField : Field;

  return (
    <FieldComponent {...rest}>
      {({
        field: { value, onBlur, ...field },
        form: { setFieldValue, handleBlur },
        meta: { touched, error },
        ...props
      }) => {
        return React.createElement(component, {
          ...rest,
          ...field,
          ...props,
          ...(component === Form.Radio ? { checked: fieldProps.value === value }
              : component === Form.Checkbox ? { checked: value }
              : { value: value ?? '' }
            ),
          ...(touched && error
            ? { error: (showErrorsInline === false) || { content: error } }
            : {}),
          onChange: (e, { value: newValue, checked }) =>
            setFieldValue(fieldProps.name, newValue ?? checked),
          onBlur: handleBlur(fieldProps.name),
        });
      }}
    </FieldComponent>
  );
};

export default SemanticField;