import gql from 'graphql-tag.macro';

export const m_insert = gql`
mutation insert_address($objects: [address_insert_input!]!){
  insert_address(objects: $objects) {
    affected_rows
  }
}`;

export const m_delete = gql`
mutation delete_address($id: Int!){
  delete_address(where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_update = gql`
mutation update_address($id: Int!, $_set: address_set_input){
  update_address(where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  }
}`;

// Query used for prefilling address update form with current values
export const q_update = gql`
query update_address($id: Int!) {
  address_by_pk(id: $id) {
    id_company
    street
    city
    district
    state
    country
    continent
    zipcode
  } 
}`;