import React, { useState } from 'react';

import { UserContext } from 'src/routes/index.jsx';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import logo from 'src/static/images/logo_inverted.png';
import EinvoiceForm from './EinvoiceForm';

// Markup and styling for dropdown nav copy pasta from https://css-tricks.com/solved-with-css-dropdown-menus/
const useStyles = createUseStyles({
  nav: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    top: 0,
    left: 0,
    background: 'hsl(0,0%,20%)',
    '& img': { height: '35px', float: 'left', margin: '6px 20px 5px 20px' },
    '& a': { textDecoration: 'none' },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      paddingLeft: 0,
      '& li': {
        '&:hover > ul, &:focus-within > ul, & ul:hover, & ul:focus': {
          visibility: 'visible',
          opacity: 1,
          display: 'block',
        },
        '& ul': {
          background: 'hsl(0,0%,15%)',
          visibility: 'hidden',
          opacity: 0,
          minWidth: '5rem',
          position: 'absolute',
          transition: 'all 0.5s ease',
          marginTop: '1rem',
          left: 0,
          display: 'none',
          '& li': {
            clear: 'both',
            width: '100%',
          },
        },
      },
    },
    '& li': {
      color: '#fff',
      background: 'hsl(0,0%,20%)',
      display: 'block',
      float: 'left',
      padding: '1rem',
      position: 'relative',
      textDecoration: 'none',
      transitionDuration: '0.5s',
      '& a': { color: '#fff' },
      '&:hover, &:focus-within': {
          background: 'hsl(0,0%,30%)',
          cursor: 'pointer',
      },
      '&:focus-within a': { outline: 'none' },
    },
  }
});

const NavBar = () => {
  const me = React.useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const [einvoiceFormOpen, setEinvoiceFormOpen] = useState(null);

  const handleLogout = (event) => {
    try {
      fetch('/auth/logout', {
        method: 'POST',
      }).then(() => {
        history.push('/login');
        localStorage.removeItem('hirlekarprecision.erp.webapp.user');
      });
    } catch (error) {
      console.error(`Error: ${error}`);  
    }
  }

  return (
    <>
      <EinvoiceForm formOpen={einvoiceFormOpen} setFormOpen={setEinvoiceFormOpen} />
      <nav className={classes.nav} role="navigation">
        <ul>
          <img alt="Hirlekar Precision logo" src={logo} />
          <li onClick={() => history.push("/companies")}>Companies</li>
          <li onClick={() => history.push("/sales_orders")}>Sales Orders</li>
          <li onClick={() => history.push("/invoices")}>Invoices</li>
          <li onClick={() => history.push("/inventories")}>Inventories</li>
          <li>Production
            <ul aria-label="submenu">
              <li onClick={() => history.push("/production/boxes")}>Boxes</li>
              <li onClick={() => history.push("/production/dial_templates")}>Dial Templates</li>
              <li onClick={() => history.push("/production/scales")}>Scales</li>
              <li onClick={() => history.push("/production/series")}>Series</li>
              <li onClick={() => history.push("/production/units")}>Units</li>
            </ul>
          </li>
          <li style={{ float: 'right' }}>{me.first_name} {me.last_name}
            <ul aria-label="submenu">
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </li>
          { (me.isAssembly || me.isFinishing || me.isQc) && 
            <li style={{ float: 'right' }} onClick={() => history.push("/scanner")}>Scanner</li>
          }
          { me.isSales && 
            <li style={{ float: 'right' }} onClick={() => setEinvoiceFormOpen(true)}>Einvoice Upload</li>
          }
        </ul>
      </nav>
    </>
  );
}

export default NavBar;