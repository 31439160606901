import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';

const ProductionScalesRoute = () =>
  <>
    <Switch>
      <Route exact path="/production/scales" component={List} />
    </Switch>
  </>

export default ProductionScalesRoute;