import { useMutation, useSubscription } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { Button, Grid, Header, Icon, Menu, Portal } from 'semantic-ui-react';
import DelayedLoader from 'src/misc/DelayedLoader';
import { fragmentNewlines } from "src/misc/helpers";
import PropertiesTable from "src/misc/PropertiesTable";
import TitledSegment from 'src/misc/TitledSegment';
import { UserContext } from 'src/routes/index.jsx';
import GaugeLineForm from '../gauge_line/Form';
import * as GAUGE_QUERIES from '../gauge_line/queries';
import GaugeLinesTable from '../gauge_line/Table';
import InventoryLineForm from '../inventory_sale_line/Form';
import * as INVENTORY_QUERIES from '../inventory_sale_line/queries';
import InventorySaleLinesTable from '../inventory_sale_line/Table';
import DuplicateLineDialog from './DialogDuplicateLine';
import MoveLineDialog from './DialogMoveLine';
import SplitLineDialog from './DialogSplitLine';
import SalesOrderForm from './Form';
import * as QUERIES from './queries.js';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  idHeader: {
    fontSize: '1.8rem',
    fontWeight: 700,
    marginBottom: 8,
  },
  statusSubheader: {
    fontSize: '1.2rem',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  mainContainer: {
    '&>*': {
      padding: '3em 3em',
      '&>h2': {
        color: 'hsl(0, 0%, 40%)',
        fontWeight: 'bold',
        marginBottom: '1em',
      },
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});


const SalesOrderDetails = ({ match }) => {
  const me = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [salesOrderFormOpen, setSalesOrderFormOpen] = useState(null);
  const [gaugeLineFormOpen, setGaugeLineFormOpen] = useState(null);
  const [inventoryLineFormOpen, setInventoryLineFormOpen] = useState(null);
  const [duplicateDialogLineId, setDuplicateDialogLineId] = useState(null);
  const [splitDialogLineId, setSplitDialogLineId] = useState(null);
  const [moveDialogLineId, setMoveDialogLineId] = useState(null);
  const [contextMenuState, setContextMenuState] = useState(null);
  const { data, error } = useSubscription(QUERIES.s_detail, { variables: { id: match.params.id }});
  const [m_update_sales_order] = useMutation(QUERIES.m_update, { variables: { id: match.params.id }});
  const [m_delete_sales_order] = useMutation(QUERIES.m_delete, { variables: { id: match.params.id }});
  const [m_inventory_sale_line_delete] = useMutation(INVENTORY_QUERIES.m_delete);
  const [m_gauge_line_delete] = useMutation(GAUGE_QUERIES.m_delete_gauge_line);
  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  const sales_order = data.sales_order_view[0];

  const handleOpenContexMenu = id_line => event => {
    event.preventDefault();
    setContextMenuState({
      id_line,
      mouseX: event.pageX - 2,
      mouseY: event.pageY - 4,
    });
  };

  let isArchived = false;
  if (sales_order.status === 'Active') {
    for (let line of [...sales_order.gauge_lines, ...sales_order.inventory_sale_lines]) {
      if (line.invoice && !['Sales', 'Production'].includes(line.invoice.status)) {
        isArchived = true;
        break;
      }
    }
  } else isArchived = true;
  
  const LineContextMenu = () => {
    if (contextMenuState != null) {
      let line, lineType;
      let lines = sales_order.gauge_lines.filter(line => line.id === contextMenuState.id_line);
      if (lines.length === 1) {
        line = lines[0];
        lineType = 'GaugeLine';
      } else {
        line = sales_order.inventory_sale_lines.filter(line => line.id === contextMenuState.id_line)[0];
        lineType = 'InventorySaleLine';
      }

      const isLineArchived = line.invoice && !['Sales', 'Production'].includes(line.invoice.status);
      return (
        <Portal open={true} onClose={() => setContextMenuState(null)} >
          <Menu vertical
            style={{
              position: 'absolute',
              top: contextMenuState.mouseY,
              left: contextMenuState.mouseX,
              zIndex: 1000,
            }}
          >
            { me.isSales && !isLineArchived && 
              <Menu.Item onClick={() => { 
                setContextMenuState(null); 
                lineType === 'GaugeLine' 
                  ? setGaugeLineFormOpen({ id: contextMenuState.id_line })
                  : setInventoryLineFormOpen({ id: contextMenuState.id_line })
                }}
              >Edit</Menu.Item> 
            }
            { me.isSales && !isLineArchived && 
              <Menu.Item onClick={() => { 
                setContextMenuState(null); 
                if (window.confirm('Confirm deletion?')) {
                  lineType === 'GaugeLine' 
                    ? m_gauge_line_delete({ variables: { id: contextMenuState.id_line }})
                    : m_inventory_sale_line_delete({ variables: { id: contextMenuState.id_line }})
                }
                }}
              >Delete<Icon name="warning sign" /></Menu.Item> 
            }
            { me.isSales && 
              <Menu.Item onClick={() => { 
                setContextMenuState(null); 
                setDuplicateDialogLineId(contextMenuState.id_line)}}
              >Duplicate</Menu.Item> 
            }
            { me.isSales && !isLineArchived && 
              <Menu.Item onClick={() => { 
                setContextMenuState(null); 
                setSplitDialogLineId(contextMenuState.id_line)}}
              >Split</Menu.Item> 
            }
            { me.isSales && !isLineArchived && 
              <Menu.Item onClick={() => { 
                setContextMenuState(null); 
                setMoveDialogLineId(contextMenuState.id_line)}}
              >Move</Menu.Item> 
            }
          </Menu>
        </Portal>
      );
    } else {
      return null;
    }
  }
  
  return (
    <div className={classes.mainContainer}>
      <SalesOrderForm formOpen={salesOrderFormOpen} setFormOpen={setSalesOrderFormOpen} />
      <GaugeLineForm formOpen={gaugeLineFormOpen} setFormOpen={setGaugeLineFormOpen} />
      <InventoryLineForm formOpen={inventoryLineFormOpen} setFormOpen={setInventoryLineFormOpen} />
      <DuplicateLineDialog idLineFrom={duplicateDialogLineId} setIdLineFrom={setDuplicateDialogLineId} />
      <SplitLineDialog idLineFrom={splitDialogLineId} setIdLineFrom={setSplitDialogLineId} />
      <MoveLineDialog idLineFrom={moveDialogLineId} setIdLineFrom={setMoveDialogLineId} />
      <LineContextMenu />
      <div className={classes.flexContainer}>
        <div className={classes.details}>
          <Grid celled='internally'>
            <Grid.Row>
              <Grid.Column width={8}>
                <div className={classes.idHeader}>{sales_order.id}</div>
                <div className={classes.statusSubheader}>{sales_order.status}</div>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Order date', value: dayjs(sales_order.order_date).format('DD MMM YY') },
                    { key: 'PO #', value: sales_order.id_purchase_order },
                    { key: 'PO Date', value: dayjs(sales_order.purchase_order_date).format('DD MMM YY') },
                    { key: 'Currency', value: `${sales_order.currency.name} (${sales_order.currency.symbol})` },
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as={Link} to={`/companies/${sales_order.customer.id}`}>{sales_order.customer.name}</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Street', value: sales_order.address.street },
                    { key: 'City', value: sales_order.address.city },
                    { key: 'Zipcode', value: sales_order.address.zipcode },
                    { key: 'District', value: sales_order.address.district },
                    { key: 'State', value: sales_order.address.state },
                    { key: 'Country', value: sales_order.address.country },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
            { sales_order.customer_notes && <Grid.Row>
              <Grid.Column>
                <Header as='h4'>Customer Notes</Header>
                <p>{fragmentNewlines(sales_order.customer_notes)}</p>
              </Grid.Column>
            </Grid.Row> }
            { sales_order.production_notes && <Grid.Row>
              <Grid.Column>
                <Header as='h4'>Production Notes</Header>
                <p>{fragmentNewlines(sales_order.production_notes)}</p>
              </Grid.Column>
            </Grid.Row> }
          </Grid>
        </div>
        <div className={classes.actions}>
          <TitledSegment title='Actions'>
            { me.isSales && sales_order.status === 'Active' && 
              <button onClick={() => {
                if (window.confirm('Confirm shortclose order?'))
                  m_update_sales_order({ variables: { _set: {
                    status: 'Shortclosed',
                    id_shortclosed_by: me.id,
                    shortclosed_at: 'now()',
                  }}});
              }}>
                Shortclose
              </button> 
            }
            { me.isSales && sales_order.status === 'Active' && 
              <button onClick={() => {
                if (window.confirm('Confirm order fulfilled?'))
                  m_update_sales_order({ variables: { _set: {
                    status: 'Fulfilled',
                    id_fulfilled_by: me.id,
                    fulfilled_at: 'now()',
                  }}});
              }}>
                Fulfilled
              </button> 
            }
            { me.isSales && 
              <button 
                disabled={isArchived}
                title={isArchived ? 'Cannot edit once some lines have shipped' : null} 
                onClick={() => setSalesOrderFormOpen({ id: sales_order.id })}
              >
                <Icon name='edit' />
                Edit
              </button>
            }
            { me.isSales && 
              <button disabled={isArchived || sales_order.gauge_lines.length !== 0 || sales_order.inventory_sale_lines.length !== 0}
                title={isArchived 
                  ? 'Cannot delete once some lines have shipped'
                  : sales_order.gauge_lines.length !== 0 || sales_order.inventory_sale_lines.length !== 0 
                    ? 'Cannot delete sales order until all lines are deleted' 
                    : null
                }
                onClick={() => window.confirm('Confirm deletion?') && m_delete_sales_order() && history.push('/sales_orders')}
              >
                <Icon color="red" name="trash" />
                Delete
              </button> 
            }
          </TitledSegment>
          <TitledSegment title='Documents'>
            {me.isSales && <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/order-acceptance`)}><Icon name="file pdf" />Sales Order</button>}
            <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/work-order`)}><Icon name="file pdf" />Work Order</button>
            <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/calibration-certificates`)}><Icon name="file pdf" />Calibration Certificates</button>
            <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/test-certificates`)}><Icon name="file pdf" />Test Certificates</button>
            <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/gauge-labels`)}><Icon name="file pdf" />Gauge Labels</button>
            <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/inner-box-labels`)}><Icon name="file pdf" />Inner Box Labels</button>
            <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/working-labels`)}><Icon name="file pdf" />Working Labels</button>
          </TitledSegment>
        </div>
      </div>
      <div>
        <h2>Gauges</h2>
        {sales_order.gauge_lines.length > 0 && <GaugeLinesTable 
          lines={sales_order.gauge_lines}
          currency={sales_order.currency.symbol}
          handleContextMenu={handleOpenContexMenu}
        />}
        { me.isSales && sales_order.status === 'Active' && 
          <Button style={{ marginTop: '32px' }} secondary onClick={() => setGaugeLineFormOpen({ id_sales_order: match.params.id })}>
            <Icon name='plus' />
            New gauge line
          </Button>
        }
      </div>
      <div>
        <h2>Inventories</h2>
        {sales_order.inventory_sale_lines.length > 0 && <InventorySaleLinesTable 
          lines={sales_order.inventory_sale_lines} 
          currency={sales_order.currency.symbol} 
          handleContextMenu={handleOpenContexMenu}
        />}
        { me.isSales && sales_order.status === 'Active' && 
          <Button style={{ marginTop: '32px' }} secondary onClick={() => setInventoryLineFormOpen({ id_sales_order: match.params.id })}>
            <Icon name='plus' />
            New inventory sale line
          </Button>
        }
      </div>
    </div>
  );   
}

export default withRouter(SalesOrderDetails);