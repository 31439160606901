import gql from 'graphql-tag.macro';
import { f_scalar_details as f_arc_scalar_details } from './arc/queries';
import { f_scalar_details as f_text_scalar_details } from './text/queries';
import { f_scalar_details as f_curved_text_scalar_details } from './curved_text/queries';
import { f_scalar_details as f_image_scalar_details } from './image/queries';
import { f_scalar_details as f_line_scalar_details } from './line/queries';
import { f_scalar_details as f_scale_scalar_details } from './scale/queries';

const f_scalar_details = gql`
  fragment dial_template_scalar_details_fragment on dial_template {
    id
    d
    fg_color
    bg_color
    has_decimal_commas
    has_square_markings
    pinion_y_offset
    porting_arrow_w
    porting_arrow_h
    porting_arrow_y
    porting_x
    porting_y
    porting_d
    unit_text_x
    unit_text_y
    unit_text_angle
    unit_text_fontname
    unit_text_fontsize
    unit_text_draw
    notes
  }
`;

export const m_insert = gql`
mutation insert_dial_template($objects: [dial_template_insert_input!]!){
  insert_dial_template(objects: $objects) {
    returning {
      id
    }
  },
  ${f_scalar_details},
}`;

export const m_delete = gql`
mutation delete_dial_template($id: Int!){
  delete_dial_template(where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_update = gql`
mutation update_dial_template($id: Int!, $_set: dial_template_set_input){
  update_dial_template(where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
  ${f_scalar_details},
}`;

export const m_duplicate_dial_template = gql`
  mutation duplicate_dial_template($id_from: ID!, $notes: String!) {
    duplicate_dial_template(id_from: $id_from, notes: $notes) {
      message
      payload
    }
  }
`;

// Query used for prefilling dial_template update form with current values
export const q_update = gql`
query update_dial_template($id: Int!) {
  dial_template_by_pk(id: $id) {
    ...dial_template_scalar_details_fragment
  },
  ${f_scalar_details},
}`;

// Query used for loading dial_template list
export const q_list = gql`
query dial_templates($limit: Int, $offset: Int, $where: dial_template_bool_exp, $order_by: [dial_template_order_by!]) {
  list: dial_template(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    id
    d
    notes
  },
}`;

// Query used for loading dial template detail
export const s_detail = gql`
  subscription dial_template($id: Int!) {
    dial_template_by_pk (id: $id) {
      ...dial_template_scalar_details_fragment
      images {
        ...dial_template_image_scalar_details_fragment
      }
      arcs (order_by: { id: asc }) {
        ...dial_template_arc_scalar_details_fragment
      }
      lines (order_by: { id: asc }) {
        ...dial_template_line_scalar_details_fragment
      }
      texts (order_by: { id: asc }) {
        ...dial_template_text_scalar_details_fragment
      }
      curved_texts (order_by: { id: asc }) {
        ...dial_template_curved_text_scalar_details_fragment
      }
      scales (order_by: { scale_number: asc }) {
        ...dial_template_scale_scalar_details_fragment
      }
    }
  },
  ${f_scalar_details},
  ${f_arc_scalar_details},
  ${f_text_scalar_details},
  ${f_curved_text_scalar_details},
  ${f_image_scalar_details},
  ${f_line_scalar_details},
  ${f_scale_scalar_details},
`;
