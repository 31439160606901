import { useEffect, useState } from 'react';

export function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => { setWidth(window.innerWidth); setHeight(window.innerHeight); }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });
  return { width, height };
}

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const formProps = {};
  Object.keys(values).forEach(key => {
    formProps[key] = { 
      value: values[key],
      error: errors[key],
      onChange: event => setValues({ ...values, [key]: event.target.value }),
    };
  });
  return [values, setValues, errors, setErrors, formProps];
}

export const useDateTime = () => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerId);
    }
  });
  const tick = () => {
    setDate(new Date());
  }

  return date;
}

export const usePageAutoscroll = (scrollInterval, disable = false) => {
  useEffect(() => {
    if (!disable) {
      const timerId = setInterval(() => tick(), scrollInterval);
      return () => {
        clearInterval(timerId);
      }
    }
  }, [disable, scrollInterval]);
  const tick = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      // you're at the bottom of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollBy({
        top: window.innerHeight*.9,
        behavior: 'smooth',
      });
    }
  }
}