import gql from 'graphql-tag.macro';

const f_box_scalar_details = gql`
  fragment box_scalar_details_fragment on box {
    id
    is_outer_box
    length
    width
    height
    weight
    notes
  }
`;

export const m_insert = gql`
mutation insert_box($objects: [box_insert_input!]!){
  insert_box(objects: $objects) {
    affected_rows
  },
  ${f_box_scalar_details},
}`;

export const m_delete = gql`
mutation delete_box($id: String!){
  delete_box(where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_update = gql`
mutation update_box($id: String!, $_set: box_set_input){
  update_box(where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
  ${f_box_scalar_details},
}`;

// Query used for prefilling box update form with current values
export const q_update = gql`
query update_box($id: String!) {
  box_by_pk(id: $id) {
    ...box_scalar_details_fragment
  },
  ${f_box_scalar_details},
}`;

// Query used for loading box list
export const q_list = gql`
query boxes($limit: Int, $offset: Int, $where: box_bool_exp, $order_by: [box_order_by!]) {
  list: box(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    id
    is_outer_box
    length
    width
    height
    weight
    notes
  }
}`;