import gql from 'graphql-tag.macro';
import { f_gauge_line_table_details } from '../gauge_line/queries';
import { f_inventory_sale_line_details } from '../inventory_sale_line/queries';

const f_sales_order_scalar_details = gql`
  fragment sales_order_scalar_details_fragment on sales_order {
    id
    order_date
    id_currency
    id_customer
    id_address
    id_purchase_order
    purchase_order_date
    customer_notes
    production_notes
  }
`;

export const m_insert = gql`
  mutation insert_sales_order($objects: [sales_order_insert_input!]!){
    insert_sales_order(objects: $objects) {
      returning {
        id
      }
    }
  },
  ${f_sales_order_scalar_details},
`;

export const m_delete = gql`
  mutation delete_sales_order($id: String!){
    delete_sales_order(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const m_update = gql`
  mutation update_sales_order($id: String!, $_set: sales_order_set_input){
    update_sales_order(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
    }
  },
  ${f_sales_order_scalar_details},
`;

// Query used for prefilling sales_order update form with current values
export const q_update = gql`
  query update_sales_order($id: String!) {
    sales_order_by_pk(id: $id) {
      ...sales_order_scalar_details_fragment
    } 
  },
  ${f_sales_order_scalar_details},
`;

export const m_duplicate_line = gql`
  mutation duplicate_sales_order_line($id_line_from: ID!, $id_line_to: ID!, $quantity: Int!) {
    duplicate_sales_order_line(id_line_from: $id_line_from, id_line_to: $id_line_to, quantity: $quantity) {
      message
    }
  }
`;

export const m_split_line = gql`
  mutation split_sales_order_line($id_line_from: ID!, $id_line_to: ID!, $quantity_move: Int!) {
    split_sales_order_line(id_line_from: $id_line_from, id_line_to: $id_line_to, quantity_move: $quantity_move) {
      message
    }
  }
`;

export const m_move_line = gql`
  mutation move_sales_order_line($id_line_from: ID!, $id_line_to: ID!) {
    move_sales_order_line(id_line_from: $id_line_from, id_line_to: $id_line_to) {
      message
    }
  }
`;

// Query used for loading data required by sales order form (e.g. dropdown fields)
export const q_form = gql`
  query sales_order_form {
    company {
      id
      name
      addresses {
        id
        street
        city
        district
        state
        country
        zipcode
      }
    }

    currency {
      id
      name
    }
  }
`;

// Query used for loading sales order detail
export const s_detail = gql`
  subscription sales_order($id: String!) {
    sales_order_view(where: { id: { _eq: $id }}) {
      id
      order_date
      id_purchase_order
      purchase_order_date
      status
      customer_notes
      production_notes
      currency {
        id
        name
        symbol
      }
      customer {
        id
        name
      }
      address {
        id
        street
        city
        state
        district
        country
        zipcode
      }
      quantity
      basic_amount
      inventory_sale_lines (order_by: { line: asc }) {
        ...inventory_sale_line_view_details_fragment
      }
      gauge_lines (order_by: { line: asc }) {
        ...gauge_line_view_table_details_fragment
      }
    }
  }, 
  ${f_gauge_line_table_details},
  ${f_inventory_sale_line_details}
`;

// Query used for loading sales order list
export const q_list = gql`
  query sales_orders($limit: Int, $offset: Int, $where: sales_order_view_bool_exp, $order_by: [sales_order_view_order_by!]) {
    list: sales_order_view (limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      order_date
      id_purchase_order
      purchase_order_date
      currency {
        id
        symbol
      }
      customer {
        id
        name
      }
      status
      basic_amount
      quantity
      id_invoices
    } 
  }
`;