import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

const InventoryRoute = () =>
  <>
    <Switch>
      <Route exact path="/inventories" component={List} />
      <Route exact path="/inventories/:id" component={Detail} />
    </Switch>
  </>

export default InventoryRoute;