import gql from 'graphql-tag.macro';

export const s_invoice_view_dispatch = gql`
  subscription invoice_view_dispatch($date_until: date) {
    invoice_view(where: {status: {_eq: "Production"}, invoice_date: {_lte: $date_until}}, order_by: {invoice_date: asc, id: desc}) {
      id
      invoice_date
      id_sales_orders
      customer_billing {
        name
      }
      address_billing {
        country
      }
      production_status {
        quantity
        qty_assembled
        qty_angle_checked
        qty_calibrated
        qty_dial_crosschecked
        qty_switch_crosschecked
        qty_finished
        qty_checked
        qty_packed
      }
    }
  }
`;