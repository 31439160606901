import { useMutation } from '@apollo/client';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { useForm } from 'src/misc/hooks.jsx';
import * as QUERIES from './queries.js';

const DialogMoveLine = ({ idLineFrom, setIdLineFrom }) => {
  const initialValues = { id_line_to: '' };
  const [m_move_line] = useMutation(QUERIES.m_move_line);
  const [formValues, setFormValues, , , formProps] = useForm(initialValues);
  const handleReset = () => { 
    setFormValues(initialValues); 
    setIdLineFrom(null); 
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await m_move_line({ variables: { ...formValues, id_line_from: idLineFrom } });
      window.alert(result.data.move_sales_order_line.message);
    } finally {
      setFormValues(initialValues);
      setIdLineFrom(null);
    }
  }
  return (
    <Modal dimmer="blurring" open={idLineFrom!=null} onClose={handleReset} size="mini">
      <Modal.Header>Move line {idLineFrom}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input label='Line to' { ...formProps.id_line_to } type="text" required />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' onClick={handleReset} ><Icon name='remove' />Discard</Button>
        <Button color='green' onClick={handleSubmit} ><Icon name='checkmark' />Ok</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DialogMoveLine;