import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const interpolateColor = (minColor, maxColor, val) => {
  minColor = hexToRgb(minColor);
  maxColor = hexToRgb(maxColor);
  
  const r = minColor.r + Math.pow(val/100, 2)*(maxColor.r-minColor.r);
  const g = minColor.g + Math.pow(val/100, 2)*(maxColor.g-minColor.g);
  const b = minColor.b + Math.pow(val/100, 2)*(maxColor.b-minColor.b);
  const a = 0.85;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const DataTable = ({ tableProps, rowProps, onClick, columns, rows, footer, handleContextMenu }) => {
  return (
    <Table {...tableProps} >
      <Table.Header>
        <Table.Row>
          {columns.filter(({ hidden }) => !hidden).map(({ value, ignoreClick, ...props }) => <Table.HeaderCell style={{ verticalAlign: 'bottom' }} {...props}>{value}</Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map((row, i) => (
          <Table.Row key={row.key} {...rowProps} >
            {columns
              .filter(({ hidden }) => !hidden)
              .map(({ key, ignoreClick, type, style: cellStyle, textAlign }) => {
                let style = { ...cellStyle }, title, cellValue;
                if (type === 'percent') {
                  const percentValue = Math.round(100*row[key].value/row[key].maxValue)
                  const backgroundColor = interpolateColor('#ffffff', '#000000', percentValue);
                  const color = percentValue > 60 ? '#ffffff' : '#000000';
                  style = { color, backgroundColor, mixBlendMode: 'multiply' };
                  title = `${percentValue}%`;
                  cellValue = row[key].value
                } else {
                  cellValue = row[key];
                }
                if (ignoreClick) style.cursor = 'default';
                return <Table.Cell
                  key={key} textAlign={textAlign} style={style} title={title}
                  onClick={(onClick && !ignoreClick) ? (() => onClick(row.key, i)) : undefined}
                  onContextMenu={handleContextMenu ? handleContextMenu(row.key) : undefined}
                >{cellValue}</Table.Cell>
              })
            }
          </Table.Row>
        ))}
      </Table.Body>
      {footer && 
        <Table.Footer>
          <Table.Row>
            {footer.map((cell, idx) => {
              if (columns[idx].hidden)
                return null;
              if (columns[idx].type === 'percent') {
                const percentValue = Math.round(100*cell.value/cell.maxValue)
                const backgroundColor = interpolateColor('#ffffff', '#000000', percentValue);
                const color = percentValue > 60 ? '#ffffff' : '#000000';
                return <Table.HeaderCell key={columns[idx].key} textAlign={columns[idx].textAlign} width={columns[idx].width} style={{ fontWeight: 900, color, backgroundColor, mixBlendMode: 'multiply' }} title={`${percentValue}%`}>{cell.value}</Table.HeaderCell>
              }
              else
                return <Table.HeaderCell key={columns[idx].key} textAlign={columns[idx].textAlign} width={columns[idx].width} style={{ fontWeight: 900 }}>{cell}</Table.HeaderCell>
            })}
          </Table.Row>
        </Table.Footer>
      }
    </Table>
  );
}

DataTable.propTypes = {
  tableProps: PropTypes.object,
  rowProps: PropTypes.object,
  onClick: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,   // Key can be any string except for 'key'
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    textAlign: PropTypes.string,
    width: PropTypes.number,
    hidden: PropTypes.bool,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  footer: PropTypes.array,
}

export default DataTable;