import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Detail from './Detail';
import List from './List';


const ProductionDialTemplateRoute = () =>
  <>
    <Switch>
      <Route exact path="/production/dial_templates" component={List} />
      <Route exact path="/production/dial_templates/:id" component={Detail} />
    </Switch>
  </>

export default ProductionDialTemplateRoute;