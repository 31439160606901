import React from 'react';
import { Route, Switch, useHistory } from "react-router-dom";
import DelayedLoader from 'src/misc/DelayedLoader';
import CompanyRoute from './company';
import GaugeLinesRoute from './gauge_line';
import InventoryRoute from './inventory';
import InvoiceRoute from './invoice';
import LoginRoute from './Login';
import NavBar from './NavBar';
import ProductionRoute from './production';
import ScannerRoute from './scanner';
import SalesOrderRoute from './sales_order';
import StatusMonitorRoute from './status_monitor';


export const UserContext = React.createContext();
const setRoles = ({ roles, ...me }) => ({
  ...me,
  roles,
  isAdmin: roles.includes('admin'),
  isSales: roles.includes('admin') || roles.includes('sales'),
  isPurchase: roles.includes('admin') || roles.includes('purchase'),
  isAccounts: roles.includes('admin') || roles.includes('accounts'),
  isAssembly: roles.includes('admin') || roles.includes('assembly'),
  isFinishing: roles.includes('admin') || roles.includes('finishing'),
  isQc: roles.includes('admin') || roles.includes('qc'),
  isDispatch: roles.includes('admin') || roles.includes('dispatch'),
  isProduction: roles.includes('admin') || roles.includes('production'),
  isDialPrinting: roles.includes('admin') || roles.includes('dial-printing'),
});

const App = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route path="/login" exact component={LoginRoute} />
      <Route path="/status_monitor" component={StatusMonitorRoute} />
      <Route path="/" render={() => {
        const me = JSON.parse(localStorage.getItem('hirlekarprecision.erp.webapp.user'));
        if (!me || (Date.now() - me.expiresAt) > 0) {
          history.push(`/login?redirect_uri=${window.location.pathname.replace('/webapp', '')}`);
          return <DelayedLoader />
        } else 
          return (
            <UserContext.Provider value={setRoles(me)}>
              <NavBar />
              <div style={{ margin: '80px 0' }}>
                <Switch>
                  <Route path="/companies" component={CompanyRoute} />
                  <Route path="/inventories" component={InventoryRoute} />
                  <Route path="/invoices" component={InvoiceRoute} />
                  <Route path="/gauge_lines" component={GaugeLinesRoute} />
                  <Route path="/sales_orders" component={SalesOrderRoute} />
                  <Route path="/production" component={ProductionRoute} />
                  <Route path="/scanner" component={ScannerRoute} />
                </Switch>
              </div>
            </UserContext.Provider>
          );
      }} />
    </Switch>
  );
}

export default App;
