import gql from 'graphql-tag.macro';

const f_scale_scalar_details = gql`
  fragment scale_scalar_details_fragment on scale {
    id
    major_markings
    minor_markings
    callout_markings
    text_markings
    calibration_markings
    crosscheck_markings
    range_from
    range_to
    notes
  }
`;

export const m_insert = gql`
mutation insert_scale($objects: [scale_insert_input!]!){
  insert_scale(objects: $objects) {
    affected_rows
  },
  ${f_scale_scalar_details},
}`;

export const m_delete = gql`
mutation delete_scale($id: Int!){
  delete_scale(where: { id: { _eq: $id } }) {
    affected_rows
  }
}`;

export const m_update = gql`
mutation update_scale($id: Int!, $_set: scale_set_input){
  update_scale(where: { id: { _eq: $id } }, _set: $_set) {
    affected_rows
  },
  ${f_scale_scalar_details},
}`;

// Query used for prefilling scale update form with current values
export const q_update = gql`
query update_scale($id: Int!) {
  scale_by_pk(id: $id) {
    ...scale_scalar_details_fragment
  },
  ${f_scale_scalar_details},
}`;

// Query used for loading scale list
export const q_list = gql`
query scales($limit: Int, $offset: Int, $where: scale_bool_exp, $order_by: [scale_order_by!]) {
  list: scale(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    ...scale_scalar_details_fragment
  },
  ${f_scale_scalar_details}
}`;