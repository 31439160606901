import gql from 'graphql-tag.macro';

const f_inventory_scalar_details = gql`
  fragment inventory_scalar_details_fragment on inventory {
    id
    model_code
    description
    hsn_code
    weight
  }
`;

export const m_insert = gql`
  mutation insert_inventory($objects: [inventory_insert_input!]!){
    insert_inventory(objects: $objects) {
      returning {
        id
      }
    }
  },
  ${f_inventory_scalar_details},
`;

export const m_delete = gql`
  mutation delete_inventory($id: Int!){
    delete_inventory(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const m_update = gql`
  mutation update_inventory($id: Int!, $_set: inventory_set_input){
    update_inventory(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
    }
  },
  ${f_inventory_scalar_details},
`;

// Query used for prefilling inventory update form with current values
export const q_update = gql`
  query update_inventory($id: Int!) {
    inventory_by_pk(id: $id) {
      ...inventory_scalar_details_fragment
    } 
  },
  ${f_inventory_scalar_details},
`;

export const m_line_duplicate = gql`
  mutation inventory_line_duplicate($input: SalesOrderLineDuplicateInput!) {
    inventory_line_duplicate(input: $input) {
      id_inventory
      line
    }
  }
`;

// Query used for loading inventory detail
export const s_detail = gql`
  subscription inventory($id: Int!) {
    inventory_view (where: { id: { _eq: $id }}) {
      id
      model_code
      description
      hsn_code
      weight
    }
  }, 
`;

// Query used for loading inventory list
export const q_list = gql`
  query inventories($limit: Int, $offset: Int, $where: inventory_view_bool_exp, $order_by: [inventory_view_order_by!]) {
    list: inventory_view (limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      model_code
      description
      qty_stock
    }
  }
`;