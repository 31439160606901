const styles = {
  header: {
    display: 'flex',
    background: 'hsl(0,0%,20%)',
    padding: '0.75rem 1rem',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    width: '100%',
    boxShadow: '0px 0 10px rgba(0, 0, 0, 0.8)',
    zIndex: 100,
    color: 'hsl(0,100%,100%)',
    '& > img': {
      height: '3rem',
    }
  },
  title: {
    flex: '1 1 auto',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: '40px',
    letterSpacing: 2,
  },
  date: {
    flex: '0 0 auto',
    fontSize: '24px',
    '& > *': {
      padding: '0.2rem'
    }
  },
  tableContainer: {
    margin: '0rem 1rem 4rem 1rem',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: 'none',
    tableLayout: 'fixed',
    fontSize: 'large',
    '& td': {
      padding: '1rem',
      lineHeight: 'normal',
    },
    '& tr.domestic': {
      backgroundColor: 'hsl(0,0%,90%)',
    },
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: '0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: 'hsl(0,0%,20%)',
    textAlign: 'center',
    boxShadow: '0px 0 10px rgba(0, 0, 0, 0.8)',
    zIndex: 100,
    '& div': {
      padding: '1rem 0rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 'medium',
    }
  },
  tableGroupHeader: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xx-large',
    letterSpacing: '3px',
    '& td': {
      paddingTop: '4rem',
      paddingBottom: '1rem',
    },
  },
  progressBar: {
    backgroundColor: 'hsl(0,0%,95%)',
    borderRadius: '1rem',
    position: 'relative',
    '& div': {
      backgroundColor: 'hsl(0,0%,60%)',
      height: '2rem',
      borderRadius: '1rem',
    },
    '& span': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)'
    }
  }
}

export default styles;