import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { createUseStyles } from "react-jss";
import { Icon } from 'semantic-ui-react';
import { UserContext } from 'src/routes/index.jsx';
import AddressForm from './Form';
import * as ADDRESS_QUERIES from './queries';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyItems: 'stretch',
    height: '40px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    backgroundColor: 'hsl(0,0%,100%)',
    border: '1px solid hsl(0,0%,80%)',
    // borderBottom: 'none',
    borderRadius: '10px 10px 0 0',
    '&>*': { whiteSpace: 'pre-wrap' },
  },
  editButton: {
    flex: '1 1 auto',
    border: 'none',
    backgroundColor: 'hsl(0,0%,15%)',
    color: 'hsl(0,0%,100%)',
    borderRadius: '0 0 0 10px',
    '&:hover': { backgroundColor: 'hsl(0,0%,0%)' },
  },
  deleteButton: {
    flex: '1 1 auto',
    border: 'none',
    backgroundColor: 'hsl(0,0%,85%)',
    borderRadius: '0 0 10px 0',
    '&:hover': { backgroundColor: 'hsl(0,0%,75%)' },
    color: 'hsl(0,100%,50%)',
  }
});


const Details = ({ address }) => {
  const me = useContext(UserContext);
  const [addressFormOpen, setAddressFormOpen] = useState(null);
  const [m_delete_address] = useMutation(ADDRESS_QUERIES.m_delete);
  const classes = useStyles();
  return (
    <div className={classes.flexContainer}>
      <AddressForm formOpen={addressFormOpen} setFormOpen={setAddressFormOpen} />
      <div className={classes.detailsContainer}>
        <span>{address.street}</span>
        <span>{address.city} - {address.zipcode}</span>
        <span>{address.district}</span>
        <span>{address.state}</span>
        <span>{address.country}</span>
      </div>
      <div className={classes.buttonContainer}>
        { me.isSales && 
          <button className={classes.editButton} onClick={() => setAddressFormOpen({ id: address.id })}>
            <Icon name='edit' />
            Edit
          </button> 
        }
        { me.isSales && 
          <button className={classes.deleteButton} 
            onClick={() => window.confirm('Confirm deletion?') && m_delete_address({ variables: { id: address.id } })}
          >
            <Icon color="red" name="trash" />
            Delete
          </button>
        }
      </div>
    </div>
  );
}

export default Details;